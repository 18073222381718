import { getApiParserBaseUrl } from '@/infrastructure/config';
import HttpClient from './httpClient';

async function doEditUser(query) {
    try {
        const response = await HttpClient.patch(`${getApiParserBaseUrl()}/user/edit`, query);
        return response;
    } catch (err) {
        throw err;
    }
}

async function doEditUserPassword(query) {
    try {
        const response = await HttpClient.patch(`${getApiParserBaseUrl()}/user/edit/password`, query);
        return response;
    } catch (err) {
        throw err;
    }
}

async function doEditLoggedOutUserPassword(query) {
    try {
        const response = await HttpClient.post(`${getApiParserBaseUrl()}/reset/password/confirm`, query);
        return response;
    } catch (err) {
        throw err;
    }
}

async function doEditUserSettings(query) {
    try {
        const response = await HttpClient.patch(`${getApiParserBaseUrl()}/user/edit/settings`, query);
        return response;
    } catch (err) {
        throw err;
    }
}

async function doGetCurrentUser() {
    try {
        const response = await HttpClient.get(`${getApiParserBaseUrl()}/user/current`);
        return response;
    } catch (err) {
        throw err;
    }
}

async function doUnsubscribeFromSetting(notificationSetting, token) {
    try {
        const response = await HttpClient.patch(`${getApiParserBaseUrl()}/unsubscribe/${notificationSetting}/${token}`);
        return response;
    } catch (err) {
        throw err;
    }
}

export {
    doEditUser,
    doEditUserPassword,
    doEditLoggedOutUserPassword,
    doGetCurrentUser,
    doEditUserSettings,
    doUnsubscribeFromSetting,
};
