<template>
    <SmartBanner />
    <div class="container-fluid p-0 heading-section mb-2">
        <div class="container overimage p-0">
            <router-link :to="{ name: 'home' }">
                <div class="row p-2 pb-0" id="heading-logo">
                    <img
                        loading="eager"
                        class="heading-logo"
                        src="@/assets/icons/ac-concept-logo-wht50.png"
                        alt="Logotipo de AlertaCoches.es"
                        height="25"
                        width="auto"
                    />
                </div>
            </router-link>
            <div class="row p-2 pt-0" id="heading-caption">
                <template v-if="this.seoProvince === null">
                    <h1 v-if="this.seoBrand === null">¿Buscas un coche de segunda mano?</h1>
                    <h1 v-else-if="this.seoModel === null">
                        ¿Buscas un {{ this.seoBrand.short_desc }} de segunda mano?
                    </h1>
                    <h1 v-else>
                        ¿Buscas un {{ this.seoBrand.short_desc }} {{ this.seoModel.short_desc }} de segunda mano?
                    </h1>
                </template>
                <template v-else>
                    <h1 v-if="this.seoModel === null">
                        ¿Buscas un {{ this.seoBrand.short_desc }} de segunda mano en {{ this.seoProvince.short_desc }}?
                    </h1>
                    <h1 v-else>
                        ¿Buscas un {{ this.seoBrand.short_desc }} {{ this.seoModel.short_desc }} de segunda mano en
                        {{ this.seoProvince.short_desc }}?
                    </h1>
                </template>
            </div>
        </div>
        <div class="container p-0 heading-picture" id="heading-picture">
            <template v-if="this.seoBrand === null">
                <img
                    id="heading-image"
                    class="img-heading"
                    :src="getHeadingImage"
                    alt="¿Buscas un coche de segunda mano?"
                />
            </template>
            <template v-else>
                <img
                    id="heading-image"
                    class="img-heading"
                    :src="getHeadingImage"
                    alt="¿Buscas un coche de segunda mano?"
                />
            </template>
        </div>
    </div>
    <SearchBox
        ref="SearchBox"
        :seoBrand="this.seoBrand"
        :seoModel="this.seoModel"
        :seoProvince="this.seoProvince"
        :isSeoPage="this.isSeoPage"
    />
</template>
<script>
import SearchBox from '@/components/structural/SearchBox.vue';
import SmartBanner from '@/components/structural/SmartBanner.vue';
import { mapState } from 'vuex';
export default {
    props: ['seoBrand', 'seoModel', 'seoProvince', 'isSeoPage'],
    name: 'HeadingSection',
    components: {
        SearchBox,
        SmartBanner,
    },
    data() {
        return {
            searchDefined: false,
            search: {},
            headerImages: [
                'https://images.pexels.com/photos/1149137/pexels-photo-1149137.jpeg?auto=compress&cs=tinysrgb&dpr=1',
                'https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg?auto=compress&cs=tinysrgb&dpr=1',
                'https://images.pexels.com/photos/170811/pexels-photo-170811.jpeg?auto=compress&cs=tinysrgb&dpr=2',
                'https://images.pexels.com/photos/1592384/pexels-photo-1592384.jpeg?auto=compress&cs=tinysrgb&dpr=2',
                'https://images.pexels.com/photos/164634/pexels-photo-164634.jpeg?auto=compress&cs=tinysrgb&dpr=2',
            ],
        };
    },
    computed: {
        ...mapState(['filter', 'ads', 'user']),
        getHeadingImage() {
            if (this.seoBrand !== null) {
                return `/img/marca/${this.getFormattedName(this.seoBrand.short_desc)}-sm.jpg`;
            }

            let idx = Math.floor(Math.random() * 5);
            const viewportWidth = window.innerWidth;
            var imageSize = '&w=945&h=500';

            if (viewportWidth < 400) {
                imageSize = '&w=430&h=300';
            }

            return this.headerImages[idx] + imageSize;
        },
    },
    setup() {},
    methods: {
        getFormattedName(brand_name) {
            return brand_name.replace(' ', '-').replace('ë', 'e').toLowerCase();
        },
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
#main-search-box {
    margin: 0px auto;
}
.heading-section {
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 210px;
    z-index: 0;
    .overimage {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        background-color: rgb(0, 0, 0, 0.35);
        color: $white;
        height: 100%;
        font-size: 22px;
        font-weight: 500;
        text-align: center;
        vertical-align: middle;
        .heading-logo {
            display: inline;
            height: 25px;
            width: auto;
            position: relative;
            float: left;
            top: 1;
            left: 10;
            border-radius: $border-radius;
        }
        p {
            margin: 6% auto;
        }
        #heading-caption {
            height: 100px;
        }
        #heading-caption h1 {
            font-size: 0.95em;
            margin: 5% auto;
            text-align: center;
        }
    }
    .heading-picture {
        height: 100%;
        .img-heading {
            z-index: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            top: 50%;
            left: 50%;
        }
    }
    .login {
        position: absolute;
        z-index: 20;
        top: 5px;
        right: 5px;
        color: $gray-0;
        button {
            padding: 5px 10px;
            font-size: 12px;
            border-radius: $border-radius !important;
            text-transform: capitalize;
            letter-spacing: 0.7px;
        }
    }
}
.search-link {
    font-weight: bold;
    font-size: 1.2em;
    letter-spacing: 1px;
    position: relative;
    z-index: 20;
    text-align: center;
    margin: 0px auto;
    a {
        text-decoration: none;
        background-color: $secondary-3;
        padding: 15px;
        border-radius: $border-radius;
        color: $white;
    }
}
.page-seo {
    margin-top: 0px !important;
}
//xs
@media (max-width: $breakpoint-xs) {
}
//sm
@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
}
//md
@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .heading-section {
        height: 230px;
        .overimage {
            max-width: 100%;
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
        }
        .heading-picture {
            max-width: 100%;
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
            overflow: hidden;
            .img-heading {
                border-bottom-left-radius: $border-radius-deep !important;
                border-bottom-right-radius: $border-radius-deep !important;
            }
        }
    }
}
//lg
@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .heading-section {
        height: 250px;
        .overimage {
            max-width: 100%;
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
        }
        .heading-picture {
            max-width: 100%;
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
            overflow: hidden;
            .img-heading {
                border-bottom-left-radius: $border-radius-deep !important;
                border-bottom-right-radius: $border-radius-deep !important;
            }
        }
    }
}
@media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
}
@media (min-width: $breakpoint-xl) {
    .heading-section {
        height: 280px;
        .overimage {
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
            #heading-caption h1 {
                font-size: 1.4em;
                margin: 6% auto;
                text-align: center;
            }
        }
        .heading-picture {
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
            overflow: hidden;
            .img-heading {
                border-bottom-left-radius: $border-radius-deep !important;
                border-bottom-right-radius: $border-radius-deep !important;
            }
        }
    }
}
</style>
