<template>
    <div class="page" ref="profile">
        <SimpleNavBar />
        <div class="container-fluid">
            <div class="container col-12 col-md-8 col-xl-6 col-xxl-5 profile-form profile-components">
                <h1 class="profile-header">Perfil</h1>
                <img
                    v-if="isVerified"
                    src="@/assets/icons/profile_edit.svg"
                    alt="Edita tus datos personales y preferencias"
                    loading="lazy"
                />
                <div class="row p-3" v-else>
                    <div class="col-12 register-cta m-auto">
                        <p><b>¡Verifica tu cuenta para usar todas las funciones!</b></p>
                        <p>Lo sabemos, es un rollo, pero es por tu seguridad.</p>
                        <p>
                            Pedirte que verifiques tu cuenta nos ayuda a evitar que usuarios utilicen mal la plataforma,
                            y que empeoren tu experiencia.
                        </p>
                        <div class="container-fluid">
                            <div class="container col-12 cta-components">
                                <v-btn
                                    block
                                    class="mt-3 btn btn-primary"
                                    :loading="isSendingVerificationEmail"
                                    @click="sendUserVerificationEmail"
                                >
                                    Enviar email de verificación
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 class="profile-section">Datos personales</h2>
                <v-btn
                    variant="text"
                    prepend-icon="mdi-account"
                    class="btn btn-secondary edit-field btn-block w-100"
                    @click="
                        this.showBasicDataChangeForm = !this.showBasicDataChangeForm;
                        this.nameAutofocus = !this.nameAutofocus;
                    "
                >
                    {{ this.userFullName }}
                </v-btn>
                <v-row id="change_name_toggle" class="change-data-toggle rounded-xl">
                    <v-dialog
                        id="name_change_overlay"
                        class="align-center justify-center filters-overlay"
                        scrollable
                        transition="dialog-bottom-transition"
                        :model-value="this.showBasicDataChangeForm"
                    >
                        <v-toolbar density="compact" color="white">
                            <v-toolbar-title class="filter-top-title ml-5">Cambia tus datos personales</v-toolbar-title>
                            <v-btn icon @click="closeEditForm">
                                <v-icon size="small">mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <div id="name_change_container" class="filters-container">
                            <div id="name_change_form_container" class="simple-form-container">
                                <v-text-field
                                    id="name_input"
                                    name="name_input"
                                    v-model="userFullName"
                                    :rules="nameRules"
                                    item-title="short_desc"
                                    item-value="value_id"
                                    label="Nombre completo"
                                    theme="light"
                                    variant="solo"
                                    class="input pa-5 pb-0"
                                    :autofocus="this.nameAutofocus"
                                ></v-text-field>
                                <v-text-field
                                    id="email_input"
                                    name="email_input"
                                    v-model="userEmail"
                                    :rules="emailRules"
                                    label="Dirección de correo electrónico"
                                    theme="light"
                                    variant="solo"
                                    class="input pa-5 pb-0"
                                    :autofocus="this.emailAutofocus"
                                    :disabled="!this.userPermissions.canChangeLoginInformation"
                                ></v-text-field>
                            </div>
                            <div class="overlay-footer">
                                <v-btn
                                    size="x-large"
                                    density="default"
                                    prepend-icon="mdi-content-save"
                                    class="btn-primary text-none rounded-xl"
                                    @click="saveBasicDataChanges"
                                >
                                    Guardar
                                </v-btn>
                                <v-btn
                                    size="x-large"
                                    variant="text"
                                    density="default"
                                    class="text-none"
                                    @click="closeEditForm"
                                >
                                    Cancelar
                                </v-btn>
                            </div>
                        </div>
                    </v-dialog>
                </v-row>
                <h2 class="profile-section">Seguridad</h2>
                <v-btn
                    variant="text"
                    prepend-icon="mdi-email"
                    class="btn btn-secondary edit-field btn-block w-100"
                    @click="
                        this.showBasicDataChangeForm = !this.showBasicDataChangeForm;
                        this.emailAutofocus = !this.emailAutofocus;
                    "
                    :disabled="!this.userPermissions.canChangeLoginInformation"
                >
                    {{ this.userEmail }}
                </v-btn>
                <v-btn
                    prepend-icon="mdi-security"
                    variant="text"
                    class="btn btn-secondary edit-field btn-block w-100"
                    @click="this.showPasswordChangeForm = !this.showPasswordChangeForm"
                    :disabled="!this.userPermissions.canChangeLoginInformation"
                >
                    Contraseña
                    <v-btn variant="tonal" class="inner-button">Cambiar</v-btn>
                </v-btn>
                <p class="notice" v-if="!this.userPermissions.canChangeLoginInformation">
                    <b>¡Atención!</b>
                    No puedes cambiar tu dirección de correo electrónico ni tu contraseña porque has iniciado sesión con
                    Google o Facebook.
                </p>
                <v-row id="change_password_toggle" class="change-data-toggle rounded-xl">
                    <v-dialog
                        id="password_change_overlay"
                        class="align-center justify-center filters-overlay"
                        scrollable
                        transition="dialog-bottom-transition"
                        :model-value="this.showPasswordChangeForm"
                    >
                        <v-toolbar density="compact" color="white">
                            <v-toolbar-title class="filter-top-title ml-5">Cambia tu contraseña</v-toolbar-title>
                            <v-btn icon @click="closeEditForm">
                                <v-icon size="small">mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <div id="password_change_container" class="filters-container">
                            <div id="password_change_form_container" class="simple-form-container">
                                <v-text-field
                                    type="password"
                                    id="password_input"
                                    name="password_input"
                                    v-model="userPassword"
                                    :rules="passwordRules"
                                    label="Nueva contraseña"
                                    theme="light"
                                    variant="solo"
                                    class="input pa-5 pb-0"
                                    autofocus
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    id="confirm_password_input"
                                    name="confirm_password_input"
                                    v-model="userPasswordConfirm"
                                    :rules="passwordRules"
                                    label="Confirma contraseña"
                                    theme="light"
                                    variant="solo"
                                    class="input pa-5 pb-0"
                                ></v-text-field>
                            </div>
                            <div class="overlay-footer">
                                <v-btn
                                    size="x-large"
                                    density="default"
                                    prepend-icon="mdi-content-save"
                                    class="btn-primary text-none rounded-xl"
                                    @click="savePasswordChanges"
                                >
                                    Guardar
                                </v-btn>
                                <v-btn
                                    size="x-large"
                                    variant="text"
                                    density="default"
                                    class="text-none"
                                    @click="closeEditForm"
                                >
                                    Cancelar
                                </v-btn>
                            </div>
                        </div>
                    </v-dialog>
                </v-row>
                <h2 class="profile-section">Configuración de notificaciones</h2>
                <v-row id="change_settings_toggle" class="change-data-toggle rounded-xl">
                    <v-switch
                        color="#248277"
                        inset
                        v-model="userSettings.mail.ad_price_change"
                        label="Avísame cuando el precio de mis favoritos cambie"
                        @change="saveSettings()"
                    ></v-switch>
                    <!-- <v-switch
                        color="#248277"
                        inset
                        v-model="userSettings.mail.ad_status_change"
                        label="Avísame de los cambios de disponibilidad de mis favoritos"
                        @change="saveSettings()"
                    ></v-switch>
                    <v-switch
                        color="#248277"
                        inset
                        v-model="userSettings.mail.ad_new_photos"
                        label="Avisame cuando mis favoritos tengan nuevas fotos"
                        @change="saveSettings()"
                    ></v-switch> -->
                    <v-switch
                        color="#248277"
                        inset
                        v-model="userCommunications"
                        label="Recibir comunicaciones comerciales y noticias"
                        @change="saveBasicDataChanges"
                    ></v-switch>
                </v-row>
                <hr />
                <v-btn
                    size="x-large"
                    density="comfortable"
                    variant="outlined"
                    prepend-icon="mdi-logout-variant"
                    class="btn-secondary text-none edit-field rounded-xl mb-5"
                    @click="logout"
                >
                    Cerrar sesión
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleNavBar from '@/components/structural/SimpleNavBar.vue';
import Footer from '@/components/structural/Footer.vue';
import { validate } from '@/infrastructure/services/validator';
import { logout } from '@/application/auth/logout';
import { sendVerificationEmail } from '@/application/auth/sendVerificationEmail';
import { edit, editPassword, editSettings } from '@/application/user/edit';
import { mapState } from 'vuex';
import store from '@/infrastructure/persistence/store/vuex';
const vuexstore = store.repository;
import { computed, reactive } from 'vue';
import { useHead } from '@unhead/vue';

export default {
    components: {
        SimpleNavBar,
        Footer,
    },
    data() {
        return {
            profilePicture: null,
            userFullName: null,
            userEmail: null,
            userCommunications: false,
            userPassword: null,
            userPasswordConfirm: null,
            showBasicDataChangeForm: false,
            showPasswordChangeForm: false,
            nameAutofocus: false,
            emailAutofocus: false,
            nameRules: validate.name,
            emailRules: validate.email,
            passwordRules: validate.password,
            isVerified: vuexstore.getters['user/isVerified'],
            isAuthenticated: vuexstore.getters['user/isAuthenticated'],
            userPermissions: vuexstore.getters['user/getUserPermissions'],
            userSettings: [],
            isSendingVerificationEmail: false,
        };
    },
    setup() {
        const siteData = reactive({
            title: 'AlertaCoches - Perfil de usuario',
            description: 'Edita tu perfil de usuario y configura tus notificaciones.',
        });

        useHead({
            title: computed(() => siteData.title),
            meta: [{ name: 'description', content: computed(() => siteData.description) }],
        });
    },
    computed: {
        ...mapState(['user']),
    },
    async beforeMount() {
        if (!this.isAuthenticated) {
            vuexstore.dispatch(
                'feedbackInterface/setErrorResponse',
                'Inicia sesión para poder acceder a tu perfil de usuario.',
            );

            this.$router.push({ name: 'login' });
        }
        this.innitialiseProfile();
    },
    methods: {
        innitialiseProfile() {
            (this.userFullName = this.user.name),
                (this.userEmail = this.user.email),
                (this.userCommunications = this.user.communications),
                (this.userSettings = this.user.settings);
            this.closeEditForm();
        },
        async logout() {
            await logout();
            this.$router.push({ name: 'home' });
        },
        async saveBasicDataChanges() {
            const userData = {
                name: this.userFullName,
                email: this.userEmail === this.user.email ? null : this.userEmail,
                communications: this.userCommunications,
            };

            await edit(userData);

            this.innitialiseProfile();
        },
        async saveSettings() {
            let settingsData = {
                mail: this.userSettings.mail,
            };

            await editSettings(settingsData);
        },
        async savePasswordChanges() {
            const passwordData = {
                password: this.userPassword,
                password_confirmation: this.userPasswordConfirm,
            };

            await editPassword(passwordData);

            this.innitialiseProfile();
        },
        async sendUserVerificationEmail() {
            this.isSendingVerificationEmail = true;
            let response = await sendVerificationEmail();

            if (response) {
                this.isSendingVerificationEmail = false;
                let responseLogOut = await logout();

                if (responseLogOut) {
                    this.$router.push({ name: 'home' });
                }
            }
        },
        closeEditForm() {
            this.showBasicDataChangeForm = false;
            this.showPasswordChangeForm = false;
            this.nameAutofocus = false;
            this.emailAutofocus = false;
        },
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.profile-header {
    font-family: $main-font;
    font-weight: bold;
    font-size: 1.2rem;
    color: $gray-1;
    margin-top: 8%;
    margin-bottom: 25px;
    letter-spacing: 0.7px;
}
.profile-section {
    font-family: $main-font;
    font-weight: bold;
    color: $gray-1;
    font-size: 0.9rem !important;
    margin-top: 25px;
    margin-bottom: 25px;
    letter-spacing: 0.7px;
}
.profile-form {
    padding-bottom: 100px;
    border-radius: $border-radius-deep;
    a {
        text-decoration: underline;
    }
}
.profile-components {
    .notice {
        font-size: 13px;
        color: $gray-2;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    img {
        width: 100%;
        height: auto;
        max-width: 400px;
        max-height: 400px;
        margin: 0 auto;
        display: block;
        margin-top: 20px;
        padding: 20px 25%;
        border: 1px solid $gray-15;
        background-color: $gray-20;
        border-radius: $border-radius-deep;
    }
    .v-row {
        margin: 0px !important;
        margin-bottom: 12px;
    }
    .v-field {
        height: 66px !important;
        font-size: 13px;
        border-radius: $border-radius-deep !important;
    }
    .v-switch {
        text-align: left;
    }
    .v-label {
        font-size: 13px;
        color: $gray-1;
    }
    .edit-field {
        justify-content: left;
        text-decoration: none;
        letter-spacing: 0.5px;
        font-size: 13px;
        height: 66px !important;
        border-radius: $border-radius;
        margin-bottom: 15px;
        span {
            margin: 1px;
            font-size: 14px;
            font-weight: bold;
            margin-left: 5px;
        }
        .inner-button {
            position: absolute;
            height: 50px;
            right: 15px;
            border-radius: $border-radius-deep;
        }
    }
    .edit-button {
        border: 1px solid $secondary !important;
    }
    .btn-primary {
        background-color: $secondary;
        color: $white;
    }
    .btn-secondary {
        background-color: $gray-20;
        color: $secondary;
        padding: 20px;
        border: none;
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        text-transform: none;
    }
}

.change-data-toggle-container {
    position: relative;
    float: left;
    z-index: 15;
    margin-top: 0px;
    padding: 0;
    .change-data-toggle {
        width: 350px;
        height: 60px;
        background-color: $white;
        overflow: hidden;
        margin: auto;
        .simple-search-toggle {
            position: relative;
            float: left;
            width: 290px;
            height: 60px;
            .icon {
                position: relative;
                float: left;
                width: 60px;
                height: 60px;
                color: $secondary !important;
                i {
                    width: 100%;
                    height: 100%;
                    svg {
                        height: 25px;
                        width: 25px;
                        margin: auto;
                    }
                }
            }
            p {
                position: relative;
                float: left;
                width: 230px;
                height: 12px;
                margin: 0;
                margin-top: 10px;
                font-size: 12px;
                color: $gray-2;
            }
            p:first-of-type {
                font-weight: bold;
                font-size: 14px;
                color: $gray-0;
            }
        }
    }
}
</style>
