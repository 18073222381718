<template>
    <v-snackbar v-if="errorResponse" :timeout="5000" v-model="errorResponse" color="#fabcbc">
        <div class="error-snackbar">
            <div class="subtitle pb-2">Ups!</div>

            <p>{{ this.errorResponse }}</p>
        </div>

        <template v-slot:actions>
            <v-btn color="#ad6262" variant="text" @click="errorResponse = false">Cerrar</v-btn>
        </template>
    </v-snackbar>

    <v-snackbar v-if="succesfulResponse" :timeout="5000" v-model="succesfulResponse" color="#B7E8D6">
        <div class="success-snackbar">
            <div class="subtitle pb-2">Genial!</div>

            <p>{{ this.succesfulResponse }}</p>
        </div>

        <template v-slot:actions>
            <v-btn color="#14746f" variant="text" @click="succesfulResponse = false">Cerrar</v-btn>
        </template>
    </v-snackbar>
    <v-dialog
        id="login-register-modal"
        class="align-center justify-center filters-overlay"
        scrollable
        transition="dialog-bottom-transition"
        :model-value="showAppSuggestion"
    >
        <v-toolbar density="compact" color="white">
            <v-toolbar-title class="filter-top-title ml-5">Disfrutando de AlertaCoches, ¡pillín!</v-toolbar-title>
            <v-btn icon @click="showAppSuggestion = !showAppSuggestion">
                <v-icon size="small">mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <img
            src="@/assets/images/app_download_popup.png"
            class="app-pop-up-image"
            alt="Descarga para potenciar la búsqueda"
        />

        <div class="container col-12 col-md-8 col-xl-6 app-perks">
            <strong>Disfruta de una experiencia nueva al buscar tu próximo coche.</strong>
            <v-list density="compact" class="mt-1">
                <v-list-item v-for="(item, i) in appPerks" :key="i" :value="item">
                    <template v-slot:prepend>
                        <v-icon :icon="item.icon"></v-icon>
                    </template>

                    <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item>
            </v-list>
        </div>

        <div class="container col-12 col-md-8 col-xl-6 mt-1 mb-1">
            <a :href="playStoreLink" target="_blank" rel="nofollow">
                <v-btn
                    class="btn mt-1 mb-2 w-100 download-app-button"
                    variant="outlined"
                    @click="showAppSuggestion = !showAppSuggestion"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        height="30px"
                        width="30px"
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 511.999 511.999"
                        xml:space="preserve"
                    >
                        <g>
                            <path
                                style="fill: #32bbff"
                                d="M382.369,175.623C322.891,142.356,227.427,88.937,79.355,6.028   C69.372-0.565,57.886-1.429,47.962,1.93l254.05,254.05L382.369,175.623z"
                            />
                            <path
                                style="fill: #32bbff"
                                d="M47.962,1.93c-1.86,0.63-3.67,1.39-5.401,2.308C31.602,10.166,23.549,21.573,23.549,36v439.96   c0,14.427,8.052,25.834,19.012,31.761c1.728,0.917,3.537,1.68,5.395,2.314L302.012,255.98L47.962,1.93z"
                            />
                            <path
                                style="fill: #32bbff"
                                d="M302.012,255.98L47.956,510.035c9.927,3.384,21.413,2.586,31.399-4.103   c143.598-80.41,237.986-133.196,298.152-166.746c1.675-0.941,3.316-1.861,4.938-2.772L302.012,255.98z"
                            />
                        </g>
                        <path
                            style="fill: #2c9fd9"
                            d="M23.549,255.98v219.98c0,14.427,8.052,25.834,19.012,31.761c1.728,0.917,3.537,1.68,5.395,2.314  L302.012,255.98H23.549z"
                        />
                        <path
                            style="fill: #29cc5e"
                            d="M79.355,6.028C67.5-1.8,53.52-1.577,42.561,4.239l255.595,255.596l84.212-84.212  C322.891,142.356,227.427,88.937,79.355,6.028z"
                        />
                        <path
                            style="fill: #d93f21"
                            d="M298.158,252.126L42.561,507.721c10.96,5.815,24.939,6.151,36.794-1.789  c143.598-80.41,237.986-133.196,298.152-166.746c1.675-0.941,3.316-1.861,4.938-2.772L298.158,252.126z"
                        />
                        <path
                            style="fill: #ffd500"
                            d="M488.45,255.98c0-12.19-6.151-24.492-18.342-31.314c0,0-22.799-12.721-92.682-51.809l-83.123,83.123  l83.204,83.205c69.116-38.807,92.6-51.892,92.6-51.892C482.299,280.472,488.45,268.17,488.45,255.98z"
                        />
                        <path
                            style="fill: #ffaa00"
                            d="M470.108,287.294c12.191-6.822,18.342-19.124,18.342-31.314H294.303l83.204,83.205  C446.624,300.379,470.108,287.294,470.108,287.294z"
                        />
                    </svg>
                    <span>Descargar en la Play Store</span>
                </v-btn>
            </a>
        </div>
    </v-dialog>
</template>
<script>
import { isDesktop, isAndroid } from '@/infrastructure/services/userDevice';

export default {
    data() {
        return {
            succesfulResponse: null,
            errorResponse: null,
            isAuthenticated: this.$store.getters['user/isAuthenticated'],
            isDeviceDesktop: false,
            showAppSuggestion: false,
            timer: null,
            appPerks: [
                {
                    icon: 'mdi-car',
                    text: 'Encuentra coches más rápido',
                },
                {
                    icon: 'mdi-heart',
                    text: 'Guarda tus anuncios favoritos',
                },
                {
                    icon: 'mdi-map-marker',
                    text: 'Busca los coches más cercanos a ti',
                },
                {
                    icon: 'mdi-trending-up',
                    text: 'Descubre las tendencias del mercado',
                },
            ],
        };
    },
    computed: {
        playStoreLink() {
            return import.meta.env.VITE_GOOGLE_PLAY_STORE_LINK; // Use `import.meta.env` for Vite
        },
    },
    methods: {
        setupAppSugestionPopup() {
            if (!isAndroid()) {
                return;
            }

            if (this.isDeviceDesktop) {
                return;
            }

            this.timer = setTimeout(() => {
                this.showAppSuggestion = true;
            }, 60000);
        },
    },
    mounted() {
        this.isDeviceDesktop = isDesktop();
        this.setupAppSugestionPopup();
    },
    beforeMount() {
        clearTimeout(this.timer);
    },
    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'feedbackInterface/setSuccesfulResponse') {
                this.succesfulResponse = state.feedbackInterface.succesfulResponse;
            }

            if (mutation.type === 'feedbackInterface/setErrorResponse') {
                this.errorResponse = state.feedbackInterface.errorResponse;
            }
        });
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
.success-snackbar {
    color: #14746f !important;
}
.error-snackbar {
    color: #ad6262 !important;
}
.subtitle {
    font-weight: bold;
    font-size: 1rem !important;
}
.app-pop-up-image {
    width: 75%;
    margin: auto;
    margin-top: 5px;
}
.v-list-item-title {
    font-size: 0.8rem !important;
}
.download-app-button {
    letter-spacing: 0.5px;
    font-size: 13px;
    height: 52px !important;
    border-radius: $border-radius;
    svg {
        position: absolute;
        left: 30px;
    }
    span {
        margin: 1px;
        font-size: 14px;
        font-weight: bold;
        text-transform: none;
    }
}

.app-perks{
    strong{
        font-size: 0.85rem;
    }
}
//md
@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .app-pop-up-image {
        margin: auto;
        width: 65%;
    }
}
//lg
@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .app-pop-up-image {
        margin: auto;
        width: 30%;
    }
}
</style>
