<template>
    <div class="page" ref="unsubscribepage">
        <SimpleNavBar />
        <div class="container-fluid">
            <div class="container col-12 col-md-8 col-xl-6 col-xxl-5 profile-form profile-components">
                <v-progress-circular
                    class="profile-header"
                    color="#67b99a"
                    indeterminate
                    :size="50"
                    :width="5"
                    v-if="unsubscribing"
                />
                <div class="row p-3 d-flex justify-content-left" v-else>
                    <template v-if="!this.errorUnsubscribing">
                        <h1 class="profile-header">
                            <v-icon color="success" icon="mdi-check-circle" size="x-large" />
                            Has desactivado correctamente estas notificaciones
                        </h1>
                        <p>
                            Recuerda que siempre puedes volver a activarlas desde
                            <router-link :to="{ name: 'profile' }">tu perfil</router-link>
                            para mantenerte informado de las últimas novedades.
                        </p>
                        <div class="login-components">
                            <router-link :to="{ name: 'home' }">
                                <v-btn class="btn btn-primary mt-3">Seguir buscando mi coche ideal</v-btn>
                            </router-link>
                        </div>
                    </template>
                    <template v-if="this.errorUnsubscribing">
                        <h1 class="profile-header">
                            <v-icon color="error" icon="mdi-alert" size="x-large" />
                            Ha ocurrido un error al desactivar las notificaciones
                        </h1>
                        <p>
                            Inténtalo de nuevo más tarde, o cambia tus preferencias de notificación desde
                            <router-link :to="{ name: 'profile' }">tu perfil</router-link>
                            .
                        </p>
                        <div class="login-components">
                            <router-link :to="{ name: 'home' }">
                                <v-btn class="btn btn-primary mt-3 w-100">Seguir buscando mi coche ideal</v-btn>
                            </router-link>
                        </div>
                        <div class="login-components">
                            <router-link :to="{ name: 'profile' }">
                                <v-btn class="btn btn-secondary mt-3 w-100">Ir a mi perfil</v-btn>
                            </router-link>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleNavBar from '@/components/structural/SimpleNavBar.vue';
import Footer from '@/components/structural/Footer.vue';
import { computed, reactive } from 'vue';
import { useHead } from '@unhead/vue';
import { unsubscribe } from '@/application/user/unsubscribe';

export default {
    components: {
        SimpleNavBar,
        Footer,
    },
    data() {
        return {
            unsubscribing: true,
            errorUnsubscribing: false,
        };
    },
    setup() {
        const siteData = reactive({
            title: 'Desactivar notificaciones',
            description: 'Desactiva las notificaciones de AlertaCoches',
        });

        useHead({
            title: computed(() => siteData.title),
            meta: [{ name: 'description', content: computed(() => siteData.description) }],
        });
    },
    methods: {
        async unsubscribe() {
            let response = await unsubscribe(this.$route.params.notificationSetting, this.$route.params.token);
            if (!response) {
                this.errorUnsubscribing = true;
            }

            this.unsubscribing = false;
        },
    },
    async mounted() {
        await this.unsubscribe();
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
</style>
