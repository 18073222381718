export default {
    namespaced: true,
    state: () => {
        return {
            id: null,
            name: null,
            email: null,
            permissions: [],
            settings: [],
            communications: null,
            verifiedAt: null,
            favourites: [],
            token: null,
            showLoginModal: false,
            showVerifyModal: false,
            origin: null,
        };
    },
    mutations: {
        logIn(state, payload) {
            state.id = payload.id;
            state.name = payload.name;
            state.email = payload.email;
            state.permissions = payload.permissions;
            state.settings = payload.settings;
            state.communications = payload.communications;
            state.verifiedAt = payload.verifiedAt;
            state.favourites = payload.favourites;
            state.token = payload.token;
        },
        setUsersData(state, payload) {
            state.id = payload.id;
            state.name = payload.name;
            state.email = payload.email;
            state.permissions = payload.permissions;
            state.settings = payload.settings;
            state.communications = payload.communications;
            state.verifiedAt = payload.verifiedAt;
            state.favourites = payload.favourites;
            state.token = payload.token;
        },
        logOut(state, payload) {
            state.id = null;
            state.name = null;
            state.email = null;
            state.permissions = [];
            state.settings = [];
            state.communications = null;
            state.verifiedAt = null;
            state.favourites = [];
            state.token = null;
        },
        addFavourite(state, payload) {
            state.favourites.push(payload);
        },
        removeFavourite(state, payload) {
            state.favourites = state.favourites.filter((favourite) => favourite !== payload);
        },
        setShowLoginModal(state, payload) {
            state.showLoginModal = payload;
        },
        setShowVerifyModal(state, payload) {
            state.showVerifyModal = payload;
        },
        setToken(state, payload) {
            state.token = payload;
        },
        setOrigin(state, payload) {
            state.origin = payload;
        },
        setUserSettings(state, payload) {
            state.settings = payload;
        },
    },
    getters: {
        isUnlocked(state) {
            return !!state.token && state.verifiedAt !== null;
        },
        isAuthenticated(state) {
            return !!state.token;
        },
        isVerified(state) {
            return state.verifiedAt !== null;
        },
        getToken(state) {
            return state.token;
        },
        getUserPermissions(state) {
            return state.permissions;
        },
        getUserSettings(state) {
            return state.settings;
        },
        getUserData(state) {
            return {
                id: state.id,
                initials: state.name
                    ? state.name
                          .split(' ')
                          .map((n) => n[0])
                          .join('')
                    : null,
                name: state.name,
                email: state.email,
                permissions: state.permissions,
                communications: state.communications,
                favourites: state.favourites,
                isVerified: state.verifiedAt !== null,
            };
        },
        showLoginModal(state) {
            return state.showLoginModal;
        },
        showVerifyModal(state) {
            return state.showVerifyModal;
        },
        getOrigin(state) {
            return state.origin;
        },
    },
    actions: {
        logIn({ commit }, payload) {
            commit('logIn', payload);
        },
        setUsersData({ commit }, payload) {
            commit('setUsersData', payload);
        },
        logOut({ commit }) {
            commit('logOut');
            dispatch('shareSearch/setDefaults', null, { root: true });
        },
        addFavourite({ commit }, payload) {
            commit('addFavourite', payload);
        },
        removeFavourite({ commit }, payload) {
            commit('removeFavourite', payload);
        },
        setShowLoginModal({ commit }, payload) {
            commit('setShowLoginModal', payload);
        },
        setShowVerifyModal({ commit }, payload) {
            commit('setShowVerifyModal', payload);
        },
        setToken({ commit }, payload) {
            commit('setToken', payload);
        },
        setOrigin({ commit }, payload) {
            commit('setOrigin', payload);
        },
        setUserSettings({ commit }, payload) {
            commit('setUserSettings', payload);
        },
    },
};
