<template>
    <div class="page" ref="login">
        <SimpleNavBar></SimpleNavBar>
        <div class="container-fluid login-components">
            <div class="container col-12 col-md-8 col-xl-6 login-form">
                <h1 class="text-left login-header">¡Inicia sesión y encuentra tu futuro coche!</h1>
                <GoogleAuth
                    :processingAuth="this.googleAuthProcessing"
                    @click="this.googleAuthProcessing = !this.googleAuthProcessing"
                />
                <FacebookAuth
                    :processingAuth="this.facebookAuthProcessing"
                    @click="this.facebookAuthProcessing = !this.facebookAuthProcessing"
                />

                <p class="mt-5 mb-5">
                    Al continuar, aceptas el
                    <a target="_blank" href="https://about.alertacoches.es/aviso-legal" @click.stop>Aviso legal</a>
                    , y confirmas que has leído la
                    <a target="_blank" href="https://about.alertacoches.es/politica-privacidad" @click.stop>
                        Política de privacidad
                    </a>
                    y la
                    <a target="_blank" href="https://about.alertacoches.es/politica-cookies" @click.stop>
                        Política de cookies
                    </a>
                    para saber cómo recopilamos, usamos y compartimos tus datos, así como cómo usamos las cookies.
                </p>
            </div>
            <div class="container col-11 col-md-8 col-xl-6 secondary-login">
                <hr />
                <p>o</p>
                <EmailAuth />
            </div>
        </div>
    </div>
</template>

<script>
import Footer from '@/components/structural/Footer.vue';
import SimpleNavBar from '@/components/structural/SimpleNavBar.vue';
import GoogleAuth from '@/components/structural/Login/GoogleAuth.vue';
import FacebookAuth from '@/components/structural/Login/FacebookAuth.vue';
import EmailAuth from '@/components/structural/Login/EmailAuth.vue';
import { getCurrentUser } from '@/application/user/get';
import { logout } from '@/application/auth/logout';

export default {
    components: {
        SimpleNavBar,
        Footer,
        GoogleAuth,
        FacebookAuth,
        EmailAuth,
    },
    data() {
        return {
            googleAuthProcessing: false,
            facebookAuthProcessing: false,
        };
    },
    async beforeMount() {
        if (this.$route.query.origin == 'app') {
            await logout();
            this.$store.dispatch('user/setOrigin', 'app');
        }

        if (this.$store.getters['user/isAuthenticated']) {
            this.$router.push({ name: 'home' });
        }

        if (this.$route.query.token) {
            if (this.$route.query.provider === 'google') {
                this.googleAuthProcessing = true;
            } else if (this.$route.query.provider === 'facebook') {
                this.facebookAuthProcessing = true;
            }
            this.$store.dispatch('user/setToken', this.$route.query.token);

            let response = await getCurrentUser();

            if (!response) {
                this.googleAuthProcessing = false;
                this.facebookAuthProcessing = false;
                this.$store.dispatch('user/setToken', null);
                return;
            }

            await new Promise((resolve) => setTimeout(resolve, 1000));

            if (this.$store?.getters['user/getOrigin'] == 'app') {
                this.$store.dispatch('user/setOrigin', null);
                try {
                    window.location.href = 'alertacoches://?token=' + this.$route.query.token;
                } catch {}
            }
            this.$router.push({ name: 'home' });
        }
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.login-form {
    padding-top: 40px;
    border-radius: $border-radius-deep;
    .login-header {
        font-family: $main-font;
        font-weight: bold;
        font-size: 1.2rem !important;
        color: $gray-2;
        margin-bottom: 25px;
        letter-spacing: 0.7px;
    }
}
.login-components {
    p {
        text-align: center;
        font-size: 12px;
        color: $gray-2;
        a {
            color: $secondary;
        }
    }
    .v-field {
        height: 66px !important;
        font-size: 13px;
        border-radius: $border-radius-deep !important;
    }
    button {
        letter-spacing: 0.5px;
        font-size: 13px;
        height: 52px !important;
        border-radius: $border-radius;
        span {
            margin: 1px;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .btn-primary {
        background-color: $secondary;
        color: $white;
    }
    .secondary-login {
        bottom: 0;
        padding: 0px;
        padding-bottom: 80px;
    }
}
</style>
