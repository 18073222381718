<template>
    <div class="page" ref="history">
        <SimpleNavBar />
        <div class="container-fluid">
            <div class="container col-12 col-md-8 col-xl-6 col-xxl-5 profile-form profile-components">
                <h1 class="profile-header">Tus historial de búsquedas</h1>
                <div class="row p-3 d-flex justify-content-left">
                    <template v-if="!this.loading && this.searches.length === 0">
                        <p>¡Parece que todavía no has hecho ninguna búsqueda! ¿A qué esperas?</p>
                        <div class="profile-components">
                            <router-link :to="{ name: 'home' }">
                                <v-btn class="btn btn-primary mt-3 float-left">Buscar ya</v-btn>
                            </router-link>
                        </div>
                    </template>
                    <v-timeline side="end">
                        <v-timeline-item v-for="n in 5" :key="n" v-if="this.loading" dot-color="#ddd" size="x-small">
                            <template v-slot:opposite>
                                <div class="timestamp-skeleton">-</div>
                            </template>
                            <v-alert class="skeleton" variant="elevated">
                                <div class="text-h6">-</div>
                                <p>-</p>
                                <p>-</p>
                                <p>-</p>
                                <p>-</p>
                                <p>-</p>
                                <p>-</p>
                            </v-alert>
                        </v-timeline-item>
                        <v-timeline-item
                            v-for="search in this.searches"
                            :key="search._id"
                            dot-color="#67b99a"
                            size="x-small"
                        >
                            <template v-slot:opposite>
                                <div class="timestamp">
                                    <span>{{ getTimeStamp(search.createdAt) }}</span>
                                </div>
                            </template>
                            <v-alert class="search-card" variant="elevated" @click="goToSearch(search)">
                                <div class="text-h6">{{ getMakeAndModelMessage(search.make, search.model) }}</div>
                                <p>{{ getPriceMessage(search.minprice, search.maxprice) }}</p>
                                <p>{{ getDatesMessage(search.minyear, search.maxyear) }}</p>
                                <p>{{ getMileageMessage(search.minmileage, search.maxmileage) }}</p>
                                <p v-if="getProvinceMessage(search.province)">
                                    {{ getProvinceMessage(search.province) }}
                                </p>
                                <p v-if="getGearTypeMessage(search.geartype)">
                                    {{ getGearTypeMessage(search.geartype) }}
                                </p>
                                <p v-if="getFuelTypeMessage(search.fueltype)">
                                    {{ getFuelTypeMessage(search.fueltype) }}
                                </p>
                            </v-alert>
                        </v-timeline-item>
                    </v-timeline>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleNavBar from '@/components/structural/SimpleNavBar.vue';
import Footer from '@/components/structural/Footer.vue';
import SearchCard from '@/components/cards/SearchCard.vue';
import store from '@/infrastructure/persistence/store/vuex';
const vuexstore = store.repository;
import { getFilterValues } from '@/application/getFilterValues/getFilterValues';
import { getListOfSearches } from '@/application/search/getListOfSearches';
import { parseSearch } from '@/infrastructure/services/searchHandler';
import { computed, reactive } from 'vue';
import { useHead } from '@unhead/vue';

export default {
    components: {
        SimpleNavBar,
        Footer,
        SearchCard,
    },
    data() {
        return {
            loading: true,
            searches: [],
            brandList: [],
            modelList: [],
            provinceList: [],
            fuelTypeList: [],
            gearTypeList: [],
            isAuthenticated: this.$store.getters['user/isAuthenticated'],
        };
    },
    setup() {
        const siteData = reactive({
            title: 'AlertaCoches - Mi historial de búsquedas',
            description: 'Consulta tu historial de búsquedas en AlertaCoches',
        });

        useHead({
            title: computed(() => siteData.title),
            meta: [{ name: 'description', content: computed(() => siteData.description) }],
        });
    },
    methods: {
        async getHistory() {
            await getListOfSearches()
                .then((response) => {
                    this.searches = response;
                    this.loading = false;
                })
                .catch((error) => {
                    vuexstore.dispatch('feedbackInterface/setErrorResponse', error);
                });
        },
        getPriceMessage(minprice, maxprice) {
            if (minprice && maxprice) {
                return `Entre ${minprice}€ y ${maxprice}€`;
            }
            if (minprice) {
                return `Precio mayor a ${minprice}`;
            }
            if (maxprice) {
                return `Precio menor a ${maxprice}`;
            }
            return 'Cualquier precio';
        },
        getDatesMessage(start, end) {
            if (start && end) {
                return `Matriculados entre ${start} y ${end}`;
            }
            if (start) {
                return `Matriculados a partir del ${start}`;
            }
            if (end) {
                return `Matriculados hasta el ${end}`;
            }
            return 'Matriculados cualquier fecha';
        },
        getMileageMessage(min, max) {
            if (min && max) {
                return `Entre ${min} y ${max} km`;
            }
            if (min) {
                return `Más de ${min} km`;
            }
            if (max) {
                return `Menos de ${max} km`;
            }
            return 'Cualquier kilometraje';
        },
        getMakeAndModelMessage(make, model) {
            if (make && model) {
                return `${this.getBrandFromList(make)} ${this.getModelFromList(model)}`;
            }
            if (make) {
                return `${this.getBrandFromList(make)}`;
            }
            if (model) {
                return `${this.getModelFromList(model)}`;
            }
            return 'Cualquier marca o modelo';
        },
        getProvinceMessage(province) {
            if (province) {
                return `${this.provinceList.find((prov) => prov.value_id === province).short_desc}`;
            }
        },
        getGearTypeMessage(gearType) {
            if (gearType) {
                if (gearType === 'at') {
                    return 'Automático';
                }
                if (gearType === 'ma') {
                    return 'Manual';
                }
            }
        },
        getGearTypeId(gearType) {
            if (gearType) {
                if (gearType === 'at') {
                    return 737;
                }
                if (gearType === 'ma') {
                    return 738;
                }
            }

            return null;
        },
        getFuelTypeId(fuelType) {
            if (fuelType) {
                if (fuelType === 'diesel') {
                    return 740;
                }
                if (fuelType === 'gasoline') {
                    return 739;
                }
                if (fuelType === 'electric') {
                    return 743;
                }
            }

            return null;
        },
        getFuelTypeMessage(fuelType) {
            if (fuelType) {
                if (fuelType === 'diesel') {
                    return 'Diésel';
                }

                if (fuelType === 'gasoline') {
                    return 'Gasolina';
                }

                if (fuelType === 'electric') {
                    return 'Híbrido/Eléctrico';
                }
            }
        },
        getBrandFromList(id) {
            return this.brandList.find((brand) => brand.value_id === id).short_desc;
        },
        getModelFromList(id) {
            return this.modelList.find((model) => model.value_id === id).short_desc;
        },
        getTimeStamp(date) {
            let dateLongNumber = date.$date.$numberLong;

            let dateObject = new Date(parseInt(dateLongNumber));
            let now = new Date();
            let diff = now - dateObject;
            let minutes = Math.floor(diff / 60000);
            let hours = Math.floor(diff / 3600000);
            let days = Math.floor(hours / 24);

            if (minutes < 60) {
                if (minutes === 1) {
                    return `Hace ${minutes} min`;
                }
                return `Hace ${minutes} mins`;
            }

            if (hours < 24) {
                if (hours === 1) {
                    return `Hace ${hours} hora`;
                }
                return `Hace ${hours} horas`;
            }

            if (days === 1) {
                return `Hace ${days} día`;
            }
            return `Hace ${days} días`;
        },
        goToSearch(search) {
            search.geartype = this.getGearTypeId(search.geartype);
            search.fueltype = this.getFuelTypeId(search.fueltype);

            let parsedSearch = parseSearch(search);
            let isDifferentToLastSearch = vuexstore.dispatch('shareSearch/isEqualToSearchContent', parsedSearch);
            if (isDifferentToLastSearch) {
                vuexstore.dispatch('shareSearch/setForceSearch', true);
                vuexstore.dispatch('shareSearch/setSearchContent', parsedSearch);
                this.$router.push({ name: 'home', params: { parsedSearch } });
            } else {
                vuexstore.dispatch('shareSearch/setForceSearch', false);
                this.$router.push({ name: 'home' });
            }
        },
    },
    async beforeMount() {
        if (!this.isAuthenticated) {
            this.$router.push({ name: 'login' });

            vuexstore.dispatch(
                'feedbackInterface/setErrorResponse',
                'Inicia sesión para poder acceder a tu historial de búsquedas.',
            );
        }

        this.brandList = await getFilterValues(1);
        this.modelList = await getFilterValues(2);
        this.provinceList = await getFilterValues(13);
        this.gearTypeList = await getFilterValues(14);
        this.fuelTypeList = await getFilterValues(15);

        await this.getHistory();
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.skeleton {
    width: 58vw;
    background-color: $gray-20;
    border-radius: $border-radius-deep;
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    .text-h6 {
        width: 150px;
        height: 20px;
        background-color: $gray-15;
        color: $gray-15;
        border-radius: $border-radius;
        margin-bottom: 10px;
    }
    p {
        width: 250px;
        min-height: 15px;
        background-color: $gray-15;
        color: $gray-15;
        border-radius: $border-radius;
        margin-bottom: 5px;
    }
}
.timestamp-skeleton {
    width: 50px;
    height: 40px;
    background-color: $gray-20;
    color: $gray-20;
    border-radius: $border-radius;
}
.timestamp {
    width: 50px;
    height: 40px;
    overflow: hidden;
    color: $secondary;
    font-size: 0.7em;
    font-weight: bold;
}
.v-timeline--vertical.v-timeline .v-timeline-item:first-child .v-timeline-divider,
.v-timeline--vertical.v-timeline .v-timeline-item:first-child .v-timeline-item__body,
.v-timeline--vertical.v-timeline .v-timeline-item:first-child .v-timeline-item__opposite {
    padding-block-start: 0;
}
.search-card {
    &:hover {
        cursor: pointer;
        @include box-shadow;
    }
    width: 58vw;
    background-color: $gray-20;
    border-radius: $border-radius-deep;
    padding: 5.5rem 1rem;
    margin-bottom: 1rem;
    text-align: left;
    .text-h6 {
        height: 20px;
        font-size: 0.9em !important;
        color: $secondary;
        margin-bottom: 10px;
        font-weight: bold;
    }
    p {
        min-height: 15px;
        font-size: 0.8em !important;
        color: $secondary;
        margin: 0px;
        margin-bottom: 5px;
    }
}

//md
@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .search-card,
    .skeleton {
        max-width: calc(100% - 100px);
    }
}
//lg
@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .search-card,
    .skeleton {
        max-width: calc(100% - 100px);
    }
}
//xl
@media (min-width: $breakpoint-xl) {
    .search-card,
    .skeleton {
        width: 35vw;
        max-width: calc(100% - 100px);
    }
}
</style>
