import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/Home.vue';
import SingleAd from '../views/SingleAd.vue';
import AdNotFound from '../views/AdNotFound.vue';
import seoRoutes from './routes/seo';
import authRoutes from './routes/auth';
import userRoutes from './routes/user';
import businessRoutes from './routes/business';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                buttonPositionIndex: 0,
            },
        },
        {
            path: '/coches-segunda-mano/anuncio/no-disponible',
            name: 'adNotFound',
            component: AdNotFound,
            meta: {
                scrollTop: 0,
                buttonPositionIndex: 100,
            },
        },
        {
            path: '/coches-segunda-mano/anuncio/:slugUrl',
            name: 'singleAd',
            component: SingleAd,
            meta: {
                scrollTop: 0,
                buttonPositionIndex: 100,
            },
        },
        ...businessRoutes,
        ...seoRoutes,
        ...authRoutes,
        ...userRoutes,
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.name === from.name) {
            return { left: 0, top: 0 };
        }

        const scrollpos = to.meta?.scrollPos || savedPosition || { left: 0, top: 0 };

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(scrollpos);
            }, 500);
        });
    },
});

router.beforeEach((to, from) => {
    to.meta.previousButtonPositionIndex = from.meta.buttonPositionIndex;
});

export default router;
