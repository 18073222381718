<template>
    <div class="page" ref="singleAd" @scroll="_scrollListener">
        <SmartBanner />
        <SimpleNavBar>
            <template v-slot:link-to-source>
                <template v-if="this.ad.sourceId && this.ad.status === 1">
                    <template v-if="this.collabDataModal === null">
                        <v-btn
                            v-if="this.ad.link"
                            prepend-icon="mdi mdi-open-in-new"
                            class="source"
                            @click="sendUserToSource(this.ad.sourceName)"
                        >
                            Ver en {{ this.ad.sourceName }}
                        </v-btn>
                        <a
                            v-else-if="this.ad.phoneNumber"
                            :href="`https://wa.me/${this.ad.phoneNumber}`"
                            target="_blank"
                            rel="nofollow"
                        >
                            <v-btn
                                prepend-icon="mdi mdi-whatsapp"
                                class="source whatsapp"
                                @click="registerUserToSource(this.ad.sourceName)"
                            >
                                Habla con {{ this.ad.sourceName }}
                            </v-btn>
                        </a>
                    </template>
                    <button
                        v-else
                        type="button"
                        class="btn source"
                        data-bs-toggle="modal"
                        data-bs-target="#collabModal"
                        @click="this.$refs.collabModal.registerCollabAdDisplay()"
                    >
                        Ver en {{ this.ad.sourceName }}
                    </button>
                </template>
                <template v-else-if="this.ad.sourceId && this.ad.status === 0">
                    <div class="link-to-source not-found p-1">
                        <img
                            class="icon m-2"
                            src="@/assets/icons/warning.svg"
                            alt="Atención! El anuncio ya no está disponible."
                        />
                        <span class="m-2">{{ $t('not_available') }}</span>
                    </div>
                </template>
            </template>
            <template
                v-slot:collab-modal
                v-if="this.ad.sourceId && this.ad.status === 1 && this.collabDataModal !== null"
            >
                <CollabModal ref="collabModal" :collabData="this.collabDataModal" :ad="this.ad" />
            </template>
        </SimpleNavBar>
        <PhotoGallery :photoArray="this.ad.images" />
        <template v-if="this.ad.sourceId">
            <!-- Ad navigation -->
            <template v-if="this.ads.items">
                <div class="nav-ad-bar navbar-light bg-light" id="nav-ad-bar">
                    <div class="d-flex justify-content-between container">
                        <template v-if="this.previousAdSlugUrl">
                            <router-link
                                :to="{
                                    name: 'singleAd',
                                    params: { slugUrl: this.parseSlugUrl(this.previousAdSlugUrl) },
                                    query: { navigateTo: 'left' },
                                }"
                            >
                                <div id="prev-ad-nav" class="navigator-prev">
                                    <img
                                        src="@/assets/icons/left-arrow.svg"
                                        alt="Ir al anuncio anterior"
                                        class="arrow"
                                    />
                                    <div class="ad-preview">
                                        <img
                                            v-if="this.previousAdImageUrl"
                                            :src="this.previousAdImageUrl"
                                            alt="Imagen previa del anuncio anterior"
                                            @error="handleImageError"
                                        />
                                        <img
                                            v-else
                                            src="@/assets/icons/no-picture.webp"
                                            alt="Imagen previa del anuncio anterior"
                                        />
                                        />
                                    </div>
                                </div>
                            </router-link>
                        </template>
                        <template v-else>
                            <div id="prev-ad-nav" class="navigator-prev">
                                <img
                                    src="@/assets/icons/left-arrow.svg"
                                    alt="Ir al anuncio anterior"
                                    class="hidden-arrow"
                                />
                                <div class="ad-preview"></div>
                            </div>
                        </template>
                        <div>
                            <router-link :to="{ name: 'home' }">
                                <v-btn
                                    id="return_to_search_button"
                                    name="return_to_search_button"
                                    title="Realiza otra búsqueda"
                                    alt="Realiza otra búsqueda"
                                    class="return-to-search"
                                    elevation="2"
                                    color="#248277"
                                    icon
                                    rounded="lg"
                                    size="small"
                                >
                                    <v-icon color="#FFFFFF">mdi-magnify</v-icon>
                                </v-btn>
                            </router-link>
                            <v-btn
                                id="add_to_favourites_button"
                                name="add_to_favourites_button"
                                title="Añade a favoritos"
                                alt="Añade a favoritos este anuncio"
                                class="return-to-search"
                                elevation="2"
                                :color="this.favouriteStatus ? '#c87878' : '#FFFFFF'"
                                icon
                                rounded="lg"
                                size="small"
                                @click="toggleFavourite(this.ad.id)"
                            >
                                <v-icon :color="this.favouriteStatus ? '#fff' : '#000'">
                                    {{ this.favouriteStatus ? 'mdi mdi-heart' : 'mdi mdi-heart-outline' }}
                                </v-icon>
                            </v-btn>
                            <v-dialog
                                id="login-register-modal"
                                class="align-center justify-center filters-overlay login-modal"
                                scrollable
                                transition="dialog-bottom-transition"
                                :model-value="showLoginModal"
                            >
                                <v-toolbar density="compact" color="white">
                                    <v-toolbar-title class="filter-top-title ml-5">
                                        ¡Inicia sesión y encuentra tu futuro coche!
                                    </v-toolbar-title>
                                    <v-btn icon @click="this.$store.dispatch('user/setShowLoginModal', false)">
                                        <v-icon size="small">mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <img
                                    src="@/assets/images/rocket.svg"
                                    class="pop-up-image"
                                    alt="Inicia sesión para potenciar la búsqueda"
                                />

                                <div class="container col-12 col-md-8 col-xl-6 login-form">
                                    <GoogleAuth
                                        :processingAuth="this.googleAuthProcessing"
                                        @click="this.googleAuthProcessing = !this.googleAuthProcessing"
                                    />
                                    <FacebookAuth
                                        :processingAuth="this.facebookAuthProcessing"
                                        @click="this.facebookAuthProcessing = !this.facebookAuthProcessing"
                                    />

                                    <p class="mt-5 mb-5">
                                        Al continuar, aceptas el
                                        <a target="_blank" href="https://about.alertacoches.es/aviso-legal" @click.stop>
                                            Aviso legal
                                        </a>
                                        , y confirmas que has leído la
                                        <a
                                            target="_blank"
                                            href="https://about.alertacoches.es/politica-privacidad"
                                            @click.stop
                                        >
                                            Política de privacidad
                                        </a>
                                        y la
                                        <a
                                            target="_blank"
                                            href="https://about.alertacoches.es/politica-cookies"
                                            @click.stop
                                        >
                                            Política de cookies
                                        </a>
                                        para saber cómo recopilamos, usamos y compartimos tus datos, así como cómo
                                        usamos las cookies.
                                    </p>
                                </div>
                                <div class="container col-11 col-md-8 col-xl-6 secondary-login">
                                    <hr />
                                    <p>o</p>
                                    <EmailAuth @click="this.$store.dispatch('user/setShowLoginModal', false)" />
                                </div>
                            </v-dialog>
                        </div>
                        <template v-if="this.nextAdSlugUrl">
                            <router-link
                                :to="{
                                    name: 'singleAd',
                                    params: { slugUrl: this.parseSlugUrl(this.nextAdSlugUrl) },
                                    query: { navigateTo: 'right' },
                                }"
                            >
                                <div id="next-ad-nav" class="navigator-next" style="right: 0">
                                    <div class="ad-preview">
                                        <img
                                            v-if="this.nextAdImageUrl"
                                            :src="this.nextAdImageUrl"
                                            alt="Imagen previa del anuncio siguiente"
                                            @error="handleImageError"
                                        />
                                        <img
                                            v-else
                                            src="@/assets/icons/no-picture.webp"
                                            alt="Imagen previa del anuncio siguiente"
                                        />
                                    </div>
                                    <img
                                        src="@/assets/icons/right-arrow.svg"
                                        alt="Ir al anuncio siguiente"
                                        class="arrow"
                                    />
                                </div>
                            </router-link>
                        </template>
                        <template v-else>
                            <div id="next-ad-nav" class="navigator-next" style="right: 0">
                                <div class="ad-preview"></div>
                                <img
                                    src="@/assets/icons/right-arrow.svg"
                                    alt="Ir al anuncio siguiente"
                                    class="hidden-arrow"
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <div class="container info-section p-3 pt-2">
                <div class="row p-0 mb-1">
                    <span class="w-50">{{ this.ad.price }}€</span>
                    <template v-if="this.ad.sourceId">
                        <div class="network-container w-50" :id="this.ad.id">
                            <template v-if="this.ad.sourceId && this.ad.status === 1">
                                <ShareNetwork
                                    v-for="network in networks"
                                    :network="network.network"
                                    :key="network.network"
                                    :style="{ backgroundColor: network.color, float: 'right' }"
                                    :url="this.currentUrl"
                                    rel="nofollow"
                                    :title="`¡Mira ${this.ad.title} por sólo ${this.ad.price}€ que he encontrado en AlertaCoches!`"
                                    :description="`Busca el coche de segunda mano fácilmente desde AlertaCoches. Encuentra otros ${this.ad.title} como este en las principales compraventas de España directamente desde un único lugar.`"
                                    quote="Busca tu coche en AlertaCoches.es"
                                    :hashtags="sharing.hashtags"
                                >
                                    <img
                                        v-if="network.network === 'whatsapp'"
                                        class="icon"
                                        src="@/assets/icons/whatsapp.svg"
                                        :alt="`Comparte este anuncio por ${network.network}`"
                                    />
                                    <img
                                        v-if="network.network === 'facebook'"
                                        class="icon"
                                        src="@/assets/icons/facebook.svg"
                                        :alt="`Comparte este anuncio por ${network.network}`"
                                    />
                                    <img
                                        v-if="network.network === 'twitter'"
                                        class="icon"
                                        src="@/assets/icons/twitter.svg"
                                        :alt="`Comparte este anuncio por ${network.network}`"
                                    />
                                </ShareNetwork>
                            </template>
                        </div>
                    </template>
                </div>
                <h1>{{ this.ad.title }}</h1>
                <h2 class="mt-4">{{ $t('car_info_header') }}</h2>
                <template v-if="this.ad.sourceId">
                    <div class="row">
                        <div class="container p-0 pt-4 col-12 col-lg-6 p-1">
                            <div class="row car-specifications d-flex">
                                <template v-if="ad.properties[2]['value']">
                                    <div class="specification-box">
                                        <img class="icon" src="@/assets/icons/calendar.svg" alt="Icono para el año" />
                                        <p>{{ ad.properties[2]['valueStr'] }}</p>
                                    </div>
                                </template>
                                <template v-if="ad.properties[1]['value']">
                                    <div class="specification-box">
                                        <img
                                            class="icon"
                                            src="@/assets/icons/distance.svg"
                                            alt="Icono para el kilometraje"
                                        />
                                        <p>{{ ad.properties[1]['valueStr'] }}</p>
                                    </div>
                                </template>
                                <template v-if="ad.properties[3]['value']">
                                    <div class="specification-box">
                                        <img
                                            class="icon"
                                            src="@/assets/icons/gastype.svg"
                                            alt="Icono para tipo de combustible"
                                        />
                                        <p>{{ ad.properties[3]['valueStr'] }}</p>
                                    </div>
                                </template>
                                <template v-if="ad.properties[0]['value']">
                                    <div class="specification-box">
                                        <img class="icon" src="@/assets/icons/power.svg" alt="Icono para la potencia" />
                                        <p>{{ ad.properties[0]['valueStr'] }}</p>
                                    </div>
                                </template>
                                <template v-if="ad.properties[4]['value']">
                                    <div class="specification-box">
                                        <img
                                            class="icon"
                                            src="@/assets/icons/geartype.svg"
                                            alt="Icono para el tipo de cambio"
                                        />
                                        <p>{{ ad.properties[4]['valueStr'] }}</p>
                                    </div>
                                </template>
                            </div>
                            <div class="p-0"><hr /></div>
                            <div class="col-12 car-specifications d-flex">
                                <p>🕒 Publicado el {{ this.ad.createdAt }}</p>
                            </div>
                            <div class="p-0"><hr /></div>
                            <div class="col-12 car-specifications d-flex">
                                <template v-if="ad.location === 'online'">
                                    <p>
                                        <img
                                            src="@/assets/icons/global.svg"
                                            alt="Icono para indicar la localización del coche."
                                        />
                                        {{ $t('location_online') }}
                                    </p>
                                </template>
                                <template v-else>
                                    <p>
                                        <img
                                            src="@/assets/icons/location.svg"
                                            alt="Icono para indicar la localización del coche."
                                        />
                                        {{
                                            `${ad.location.city_name} ${ad.location.zip_code}, ${
                                                ad.location.province_trl ?? ad.location.province_name
                                            }`
                                        }}
                                    </p>
                                </template>
                            </div>
                        </div>
                        <v-alert
                            v-if="ad.isDuplicated"
                            class="m-3"
                            icon="mdi mdi-alert"
                            title="Lo hemos visto antes... "
                            text="Parece que este vehículo está disponible en múltiples localizaciones. Asegúrate de contactar con el vendedor antes de visitar sus instalaciones."
                            type="info"
                            variant="tonal"
                        ></v-alert>
                        <!-- <v-card
                            class="contact-form col-6 pl-3 pr-3"
                            v-if="ad.isIntegration"
                            variant="plane"
                            color="#036666"
                        >
                            <v-row align="center">
                                <v-col class="text-right" cols="3">
                                    <v-img
                                        loading="extra-lazy"
                                        :alt="`Logotipo de ${ad.customer.visibleName}, ${ad.customer.shortDescription}`"
                                        :src="ad.customer.logoUrl.replace('${variant}', 'public')"
                                    ></v-img>
                                </v-col>
                                <v-col cols="12">
                                    <h3>Datos del vendedor</h3>
                                    <p class="small">
                                        <strong>{{ ad.customer.visibleName }}</strong>
                                        - {{ ad.customer.address }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-card-actions class="p-0">
                                <v-btn variant="tonal" prepend-icon="mdi mdi-web" @click="callSeller">
                                    Catálogo de {{ ad.customer.visibleName }}
                                </v-btn>
                                <v-btn
                                    color="secondary"
                                    variant="plane"
                                    prepend-icon="mdi mdi-phone"
                                    @click="callSeller"
                                >
                                    Llamar
                                </v-btn>
                                <v-btn
                                    color="secondary"
                                    variant="plane"
                                    prepend-icon="mdi mdi-email"
                                    @click="sendContactMessage"
                                >
                                    {{ ad.customer.email }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <div
                            :class="ad.isDuplicated || ad.isIntegration ? 'col-12' : 'col-12 col-lg-6'"
                            class="container p-0 pt-4 maps"
                        >       
                         -->
                        <div :class="ad.isDuplicated ? 'col-12' : 'col-12 col-lg-6'" class="container p-0 pt-4 maps">
                            <template v-if="ad.location === 'online'">
                                <iframe
                                    title="Mapa de la localización del anuncio"
                                    width="100%"
                                    height="100%"
                                    style="border: 0"
                                    loading="extra-lazy"
                                    allowfullscreen
                                    referrerpolicy="no-referrer-when-downgrade"
                                    :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyB-x6G3Vny0IiTcKJQ0ZKB_y5_cWxOO7ps
                                    &q=${this.ad.sourceName}`"
                                ></iframe>
                            </template>
                            <template v-else>
                                <iframe
                                    title="Mapa de la localización del anuncio"
                                    width="100%"
                                    height="100%"
                                    style="border: 0"
                                    loading="extra-lazy"
                                    allowfullscreen
                                    referrerpolicy="no-referrer-when-downgrade"
                                    :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyB-x6G3Vny0IiTcKJQ0ZKB_y5_cWxOO7ps
                                    &q=${ad.location.city_name} ${ad.location.zip_code}, ${ad.location.province_name}`"
                                ></iframe>
                            </template>
                        </div>
                    </div>
                </template>
                <div class="row p-0">
                    <RevisionCalculator
                        v-if="ad && makeList.length > 0 && modelList.length > 0"
                        :ad="ad"
                        :makeList="makeList"
                        :modelList="modelList"
                    />
                    <ReportLink
                        loading="extra-lazy"
                        v-if="ad && makeList.length > 0 && modelList.length > 0"
                        :ad="ad"
                        :makeList="makeList"
                        :modelList="modelList"
                        :vehicleIdentifier="vehicleIdentifier"
                    />
                </div>
                <template v-if="this.collabDataBanner !== null">
                    <div class="row">
                        <div class="container-fluid" id="banner-area">
                            <div class="container p-0 pb-2 pt-4">
                                <CollabBanner :collabData="this.collabDataBanner" />
                            </div>
                        </div>
                    </div>
                </template>
                <h2 class="mt-4 mb-3">{{ $t('other_similar_cars_header') }}</h2>
                <div class="container-fluid mb-4 pb-4" id="similar-cars-area">
                    <div class="container p-0 car-carousel mb-4">
                        <div class="row p-0" id="preview-car-carousel" ref="preview-car-carousel">
                            <template v-if="!similarAds.items">
                                <SkeletonAdCard v-for="i in 12" :key="i" />
                            </template>
                            <template v-else>
                                <AdCard v-for="ad in similarAds.items" :key="ad.id" :card="ad" />
                                <p v-if="similarAds.items.length === 0">
                                    {{ $t('no_similar_ads_found') }}
                                </p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="skeleton-nav-ad-bar navbar-light bg-light">
                <div class="d-flex justify-content-between container">
                    <div id="prev-ad-nav" class="navigator-prev">
                        <img src="@/assets/icons/left-arrow.svg" alt="Ir al anuncio anterior" class="arrow" />
                        <div class="ad-preview"></div>
                    </div>
                    <div>
                        <div id="search-ad-nav" class="navigator-search"></div>
                        <div id="search-ad-nav" class="navigator-search"></div>
                    </div>
                    <div id="next-ad-nav" class="navigator-next" style="right: 0">
                        <div class="ad-preview"></div>
                        <img src="@/assets/icons/right-arrow.svg" alt="Ir al anuncio siguiente" class="arrow" />
                    </div>
                </div>
            </div>
            <div class="container skeleton-info-section p-3 pt-2">
                <div class="row p-0 mb-3">
                    <span class="w-50">
                        <div></div>
                    </span>
                    <div class="network-container w-50">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <h1>-</h1>
                <p>-</p>

                <h2 class="mt-4">{{ $t('car_info_header') }}</h2>
                <div class="row car-specifications d-flex">
                    <div class="specification-box">
                        <div></div>
                        <p>-</p>
                    </div>
                    <div class="specification-box">
                        <div></div>
                        <p>-</p>
                    </div>
                    <div class="specification-box">
                        <div></div>
                        <p>-</p>
                    </div>
                    <div class="specification-box">
                        <div></div>
                        <p>-</p>
                    </div>
                    <div class="specification-box">
                        <div></div>
                        <p>-</p>
                    </div>
                </div>
                <h2 class="mt-4 mb-3">{{ $t('other_similar_cars_header') }}</h2>
                <div class="container-fluid" id="similar-cars-area">
                    <div class="container p-0 car-carousel">
                        <div class="row p-0" id="preview-car-carousel" ref="preview-car-carousel">
                            <template v-if="!similarAds.items">
                                <SkeletonAdCard v-for="i in 12" :key="i" />
                            </template>
                            <template v-else>
                                <AdCard v-for="ad in similarAds.items" :key="ad.id" :card="ad" />
                                <p v-if="similarAds.items.length === 0">
                                    {{ $t('no_similar_ads_found') }}
                                </p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { getAdIdFromUrl } from '@/application/getAdIdFromUrl/getAdIdFromUrl';
import { getFilterValues } from '@/application/getFilterValues/getFilterValues';
import { getValuesBasedOnParent } from '@/application/getFilterValues/getFilterValuesBasedOnParent';
import { searchAnuncio } from '@/application/searchAnuncio/searchAnuncio';
import { searchSimilarAnuncio } from '@/application/searchAnuncio/searchSimilarAnuncio';
import { searchSingleAnuncio } from '@/application/searchAnuncio/searchSingleAnuncio';
import { toggleFavouriteStatus } from '@/application/favourite/toggleFavourite';
import { sendContactMessage } from '@/application/business/sendContactMessage';
import { trackEvent } from '@/application/tracking/track';
import { validate } from '@/infrastructure/services/validator';
import AdCard from '@/components/cards/AdCard.vue';
import SkeletonAdCard from '@/components/cards/SkeletonAdCard.vue';
import CollabBanner from '@/components/collab/CollabBanner.vue';
import RevisionCalculator from '@/components/collab/client/RevisamosElCoche/RevisionCalculator.vue';
import ReportLink from '@/components/collab/client/Carfax/ReportLink.vue';
import CollabModal from '@/components/collab/CollabModal.vue';
import PhotoGallery from '@/components/structural/PhotoGallery.vue';
import SimpleNavBar from '@/components/structural/SimpleNavBar.vue';
import GoogleAuth from '@/components/structural/Login/GoogleAuth.vue';
import FacebookAuth from '@/components/structural/Login/FacebookAuth.vue';
import EmailAuth from '@/components/structural/Login/EmailAuth.vue';
import SmartBanner from '@/components/structural/SmartBanner.vue';
import { mapState } from 'vuex';
import { computed, reactive } from 'vue';
import { useHead } from '@unhead/vue';
import noPicturePlaceholder from '@/assets/icons/no-picture.webp';

export default {
    components: {
        SimpleNavBar,
        CollabBanner,
        RevisionCalculator,
        ReportLink,
        PhotoGallery,
        AdCard,
        SkeletonAdCard,
        CollabBanner,
        CollabModal,
        GoogleAuth,
        FacebookAuth,
        EmailAuth,
        SmartBanner,
    },
    data() {
        return {
            ad: {
                id: null,
                images: [],
            },
            contactForm: {
                name: '',
                email: '',
                phone: '',
                city: '',
                message: '',
                terms: false,
                contactInProgress: false,
            },
            nameRules: validate.name,
            emailRules: validate.email,
            phoneNumberRules: validate.phoneNumber,
            cityRules: validate.name,
            messageRules: validate.message,
            termsRules: validate.termsContact,
            vehicleIdentifier: null,
            currentAdIdx: 0,
            previousAdSlugUrl: '',
            previousAdImageUrl: '',
            nextAdSlugUrl: '',
            nextAdImageUrl: '',
            adYear: null,
            adMillage: null,
            currentUrl: '',
            sharing: {
                hashtags: 'segundamano,coches,coche,cochessegundamano,oportunidades,buscador,alertacoches,indexador',
            },
            networks: [
                { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
                { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
            ],
            scroll: {
                prevPosition: 0,
                coords: [Math.round(0), Math.round(0)],
            },
            collabDataBanner: null,
            collabDataModal: null,
            make: null,
            makeList: [],
            model: null,
            modelList: [],
            collabAds: null,
            favouriteStatus: null,
            seoPower: '',
            seoMileage: '',
            seoYear: '',
            seoFuel: '',
            seoGear: '',
            googleAuthProcessing: false,
            facebookAuthProcessing: false,
        };
    },
    computed: {
        ...mapState(['ads', 'shareSearch', 'similarAds', 'user']),
        ...mapState({
            showLoginModal: (state) => state.user.showLoginModal,
        }),
    },
    async beforeMount() {
        if (this.$route.query.provider === 'google') {
            this.googleAuthProcessing = true;
        } else if (this.$route.query.provider === 'facebook') {
            this.facebookAuthProcessing = true;
        }
        this.$store.dispatch('user/setShowLoginModal', false);
        this.$store.dispatch('user/setShowVerifyModal', false);
        await this.setContent();
    },
    methods: {
        _scrollListener() {
            if (this.ads.items) {
                const elmnt = document.getElementById('main-photo-area');
                const navBar = document.getElementById('nav-ad-bar');
                if (elmnt) {
                    let clientRect = elmnt.getBoundingClientRect();
                    this.scroll.coords = [Math.round(clientRect.top), Math.round(clientRect.left)];
                    if (this.scroll.prevPosition > clientRect.top + 120) {
                        navBar.classList.add('hidden');
                        this.scroll.prevPosition = clientRect.top;
                    } else if (this.scroll.prevPosition < clientRect.top - 80) {
                        navBar.classList.remove('hidden');
                        this.scroll.prevPosition = clientRect.top;
                    }
                }
            }
        },
        handleImageError(event) {
            event.target.src = noPicturePlaceholder;
        },
        async setContent() {
            this.ad.id = await getAdIdFromUrl(this.$route.params.slugUrl);
            this.ad = await searchSingleAnuncio(this.ad.id);
            for (let i = 0; i < this.ad.properties.length; i++) {
                if (this.ad.properties[i]['key'] === 'VIN') {
                    this.vehicleIdentifier = this.ad.properties[i]['value'];
                }
            }
            if (this.ad.isIntegration) {
                await trackEvent('customer_vehicle_page_view', {
                    customer: this.ad.customer,
                });
            }
            this.makeList = await getFilterValues(1);
            this.modelList = await getValuesBasedOnParent(2, this.ad.make);
            this.make = await this.getShortDesc(this.ad.make, this.makeList);
            this.model = await this.getShortDesc(this.ad.model, this.modelList);

            if (this.ad.properties) {
                if (this.ad.properties[0]) {
                    this.seoPower = `${this.ad.properties[0]['value']} caballos.`;
                }
                if (this.ad.properties[1]) {
                    this.seoMileage = `${this.ad.properties[1]['value']} km.`;
                }
                if (this.ad.properties[2]) {
                    this.seoYear = `${this.ad.properties[2]['value']}.`;
                }
                if (this.ad.properties[3]) {
                    if (this.ad.properties[3]['value'] === 'gasoline') {
                        this.seoFuel = 'Gasolina.';
                    } else if (this.ad.properties[3]['value'] === 'diesel') {
                        this.seoFuel = 'Diésel.';
                    } else {
                        this.seoFuel = 'Híbrido/Eléctrico.';
                    }
                }
                if (this.ad.properties[4]) {
                    if (this.ad.properties[4]['value'] === 'ma') {
                        this.seoGear = 'Cambio manual.';
                    } else {
                        this.seoGear = 'Cambio automático.';
                    }
                }
            }
            const siteData = reactive({
                title: `${this.ad.title} de Segunda Mano | ${this.ad.price}€ en ${this.ad.location.city_name} (${
                    this.ad.location.province_trl ?? this.ad.location.province_name
                })`,
                description: `${this.ad.title} de segunda mano. ${this.seoPower} ${this.seoMileage} ${this.seoYear} ${
                    this.seoFuel
                } ${this.seoGear} Disponible en ${this.ad.location.city_name}, ${
                    this.ad.location.province_trl ?? this.ad.location.province_name
                }. Precio: ${this.ad.price} €  ¡Encuentra más detalles en AlertaCoches!`,
                ogTitle: `¡Mira ${this.ad.title} por sólo ${this.ad.price}€ que he encontrado en AlertaCoches!`,
                ogDescription: `Busca el coche de segunda mano fácilmente desde AlertaCoches. Encuentra otros ${this.ad.title} como este en las principales compraventas de España directamente desde un único lugar.`,
                ogImage: `${this.ad.images[0]}`,
                keywords:
                    `${this.make} ${this.model} ${this.ad.properties[2]['value']} segunda mano, ${this.make} ${this.model} ${this.ad.properties[2]['value']} ocasión,` +
                    `${this.ad.title}, ${this.make} ${this.model} usado, Compra ${this.make} ${this.model} de segunda mano, ${this.make} ${this.model} ${this.seoFuel},` +
                    `${this.make} ${this.model} ${this.ad.location.city_name}, ${this.make} ${this.model} ${
                        this.ad.location.province_trl ?? this.ad.location.province_name
                    }, ` +
                    `${this.make} ${this.model} kilometraje ${this.ad.properties[1]['value']}, ${this.model} ${this.ad.properties[2]['value']} en venta, ${this.make} ${this.model} precio`,
            });

            useHead({
                title: computed(() => siteData.title),
                meta: [
                    { name: 'description', content: computed(() => siteData.description) },
                    { name: 'keywords', content: computed(() => siteData.keywords) },
                    { name: 'og:title', content: computed(() => siteData.ogTitle) },
                    { name: 'og:description', content: computed(() => siteData.ogDescription) },
                    { name: 'og:image', content: computed(() => siteData.ogImage) },
                    { name: 'og:url', content: window.location.href },
                    { name: 'twitter:title', content: computed(() => siteData.ogTitle) },
                    { name: 'twitter:description', content: computed(() => siteData.ogDescription) },
                    { name: 'twitter:image', content: computed(() => siteData.ogImage) },
                ],
            });
            this.currentUrl = window.location.href;
            this.favouriteStatus = this.isFavourite(this.ad);

            if (this.ad.sourceId) {
                //this.collabDataBanner = {
                //    type: 'banner',
                //    make: this.make,
                //    model: this.model,
                //    province: this.ad.location.province,
                //};
                //this.collabDataModal = {
                //    type: 'popup',
                //    make: this.make,
                //    model: this.model,
                //    province: this.ad.location.province,
                //};

                //Get similar searches
                await this.setAdNavigation();
                await this.doSimilarAdsSearch();
            }
        },
        scrollToTop() {
            let elmnt = document.getElementById('main-photo-area');
            if (elmnt) {
                elmnt.scrollIntoView({
                    behavior: 'auto',
                    block: 'start',
                    inline: 'start',
                });
            }
        },
        async doSimilarAdsSearch() {
            await searchSimilarAnuncio({
                page: 1,
                perpage: 20,
                minprice: this.getMinPrice(this.ad.price),
                maxprice: this.getMaxPrice(this.ad.price),
                make: this.ad.make,
                model: this.ad.model,
                minmileage: this.getMinMileage(this.ad.properties[1]['value']),
                maxmileage: this.getMaxMileage(this.ad.properties[1]['value']),
                minyear: this.ad.properties[2]['value'] - 1,
                maxyear:
                    this.ad.properties[2]['value'] + 2 > new Date().getFullYear()
                        ? new Date().getFullYear()
                        : this.ad.properties[2]['value'] + 2,
            });
        },
        getMinPrice(selectedPrice) {
            let calculatedPrice = Math.floor(selectedPrice - (selectedPrice / 100) * 25);
            return calculatedPrice <= 0 ? 0 : calculatedPrice;
        },
        getMaxPrice(selectedPrice) {
            let calculatedPrice = Math.floor(selectedPrice + (selectedPrice / 100) * 25);
            return calculatedPrice >= 1000000 ? 1000000 : calculatedPrice;
        },
        getMinMileage(selectedMileage) {
            let calculatedMileage = Math.floor(selectedMileage - (selectedMileage / 100) * 75);
            return calculatedMileage <= 0 ? 0 : calculatedMileage;
        },
        getMaxMileage(selectedMileage) {
            let calculatedMileage = Math.floor(selectedMileage + (selectedMileage / 100) * 75);
            return calculatedMileage >= 1000000 ? 1000000 : calculatedMileage;
        },
        async setAdNavigation() {
            if (this.ads.items) {
                for (var arrayIndex = this.currentAdIdx; arrayIndex < this.ads.items.length; arrayIndex++) {
                    if (this.ad.id === this.ads.items[arrayIndex].id) {
                        this.currentAdIdx = arrayIndex;

                        //First ad
                        if (arrayIndex === 0) {
                            this.previousAdSlugUrl = '';
                            this.previousAdImageUrl = '';
                        } else {
                            let idxToDeduct = 1;
                            if (this.ads.items[arrayIndex - 1].isCollabAd) {
                                idxToDeduct = 2;
                            }
                            this.previousAdSlugUrl = this.ads.items[arrayIndex - idxToDeduct].slugUrl;
                            this.previousAdImageUrl = this.ads.items[arrayIndex - idxToDeduct].images[0];
                        }

                        //Last ad
                        if (arrayIndex === this.ads.items.length - 1) {
                            this.nextAdSlugUrl = '';
                            this.nextAdImageUrl = '';
                        } else {
                            let idxToAdd = 1;
                            if (this.ads.items[arrayIndex + 1].isCollabAd) {
                                idxToAdd = 2;
                            }
                            this.nextAdSlugUrl = this.ads.items[arrayIndex + idxToAdd].slugUrl;
                            this.nextAdImageUrl = this.ads.items[arrayIndex + idxToAdd].images[0];
                        }

                        //Get next page
                        if (arrayIndex >= this.ads.items.length - 3) {
                            this.loadMoreAds();
                        }
                    }
                }
            }
        },
        async loadMoreAds() {
            let previousSearch = this.shareSearch.searchContent;
            var search = {
                make: `previousSearch`.make || null,
                model: previousSearch.model || null,
                state: previousSearch.state || null,
                minprice: previousSearch.minprice || 750,
                maxprice: previousSearch.maxprice || 200000,
                minyear: previousSearch.minyear || 2000,
                maxyear: previousSearch.maxyear || new Date().getFullYear(),
                minmileage: previousSearch.minmileage || 0,
                maxmileage: previousSearch.maxmileage || 1000000,
                minpower: previousSearch.minpower || 0,
                maxpower: previousSearch.maxpower || 1200,
                geartype: previousSearch.geartype || null,
                fueltype: previousSearch.fueltype || null,
                page: previousSearch.page,
            };
            search.page++;
            await searchAnuncio(search);
            this.$store.dispatch('shareSearch/setSearchContent', search);
        },
        parseSlugUrl(slugUrl) {
            return slugUrl.toLowerCase().replaceAll('.', '').replaceAll('/', '');
        },
        async getShortDesc(id, haystack) {
            if (!id || !haystack || haystack.length === 0 || !Array.isArray(haystack)) {
                return null;
            }

            let obj = haystack.find((o) => o.value_id === id);

            if (!obj) {
                return null;
            }

            return obj.short_desc;
        },
        sendUserToSource(sourceName) {
            if (this.$store.getters['user/isAuthenticated']) {
                window.open(this.ad.link, '_blank').focus();
                this.registerUserToSource(sourceName);
            } else {
                this.$store.dispatch('user/setShowLoginModal', true);
            }
        },
        registerUserToSource(sourceName) {
            gtag('event', 'ac_visit_source', {
                make: this.make,
                model: this.model,
                sourceName: sourceName,
            });
        },
        toggleFavourite(id) {
            if (this.$store.getters['user/isAuthenticated']) {
                toggleFavouriteStatus(id);
                this.favouriteStatus = !this.favouriteStatus;
            } else {
                this.$store.dispatch('user/setShowLoginModal', true);
            }
        },
        isFavourite(ad) {
            return this.user.favourites.includes(ad.id);
        },
        toggleLoginModal() {
            this.showLoginModal = !this.showLoginModal;
        },
        async sendContactForm() {
            if (this.$refs.contactForm.validate()) {
                this.contactForm.contactInProgress = true;

                const response = await sendContactMessage({
                    name: this.contactForm.name,
                    email: this.contactForm.email,
                    phone: this.contactForm.phone,
                    city: this.contactForm.city,
                    message: this.contactForm.message,
                    customerSlug: this.customer.slug,
                });

                if (response) {
                    await trackEvent('customer_page_contact', {
                        customer: this.customer,
                    });
                    this.$refs.contactForm.reset();
                }

                this.contactInProgress = false;
            }
        },
        isFilled() {
            return this.name && this.email && this.phone && this.message && this.terms;
        },
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.nav-ad-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 5px;
    z-index: 300;
    height: 70px;
    display: flex;
    justify-content: space-between;
    float: inline-end;
    @include nav-bar-shadow;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
    div {
        .navigator-search {
            height: 35px;
            width: 35px;
            margin: 2px auto;
            border-radius: $border-radius;
            background-color: $primary-4;
            background-image: $gray-1;
            text-decoration: none;
            color: $white;
            cursor: pointer;
            img {
                margin-top: 10px;
                width: 18px;
                height: auto;
            }
        }
        .navigator-prev {
            align-items: center;
            cursor: pointer;
            .ad-preview {
                height: 45px;
                width: 60px;
                border-radius: $border-radius;
                overflow: hidden;
                float: right;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .navigator-next {
            align-items: center;
            cursor: pointer;
            .ad-preview {
                height: 45px;
                width: 60px;
                border-radius: $border-radius;
                overflow: hidden;
                float: left;
                img {
                    object-fit: cover;
                    width: 100%;
                    border-radius: $border-radius;
                    height: 100%;
                }
            }
        }
        .return-to-search {
            margin: 2px 5px;
            border: 0;
            border-radius: $border-radius-deep !important;
        }
        .arrow {
            height: 20px;
            width: auto;
            margin: 12px auto;
            -webkit-animation: ease flickerAnimation 1s infinite;
            -moz-animation: ease flickerAnimation 1s infinite;
            -o-animation: ease flickerAnimation 1s infinite;
            animation: ease flickerAnimation 1s infinite;
        }
        .hidden-arrow {
            opacity: 0;
            height: 20px;
            width: auto;
            margin: 15px auto;
        }
    }
}
.hidden {
    margin-bottom: -120px;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
}

.skeleton-nav-ad-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 5px;
    z-index: 300;
    height: 70px;
    display: flex;
    justify-content: space-between;
    float: inline-end;
    @include nav-bar-shadow;
    div {
        .navigator-search {
            position: relative;
            float: left;
            height: 35px;
            width: 35px;
            margin: 5px;
            border-radius: $border-radius-deep;
            background-color: $gray-10;
            -webkit-animation: ease flickerAnimation 1s infinite;
            -moz-animation: ease flickerAnimation 1s infinite;
            -o-animation: ease flickerAnimation 1s infinite;
            animation: ease flickerAnimation 1s infinite;
            text-decoration: none;
            color: $white;
            cursor: pointer;
        }
        .navigator-prev {
            align-items: center;
            cursor: pointer;
            .ad-preview {
                height: 45px;
                width: 60px;
                border-radius: $border-radius;
                float: right;
                background-color: $gray-10;
                -webkit-animation: ease flickerAnimation 1s infinite;
                -moz-animation: ease flickerAnimation 1s infinite;
                -o-animation: ease flickerAnimation 1s infinite;
                animation: ease flickerAnimation 1s infinite;
            }
        }
        .navigator-next {
            align-items: center;
            cursor: pointer;
            .ad-preview {
                height: 45px;
                width: 60px;
                border-radius: $border-radius;
                float: left;
                background-color: $gray-10;
                -webkit-animation: ease flickerAnimation 1s infinite;
                -moz-animation: ease flickerAnimation 1s infinite;
                -o-animation: ease flickerAnimation 1s infinite;
                animation: ease flickerAnimation 1s infinite;
            }
        }
        .arrow {
            height: 20px;
            width: auto;
            margin: 15px auto;
            -webkit-animation: ease flickerAnimation 1s infinite;
            -moz-animation: ease flickerAnimation 1s infinite;
            -o-animation: ease flickerAnimation 1s infinite;
            animation: ease flickerAnimation 1s infinite;
        }
        .hidden-arrow {
            opacity: 0;
            height: 20px;
            width: auto;
            margin: 15px auto;
        }
    }
}
.info-section {
    align-items: left;
    text-align: left;
    color: $secondary-4;
    font-weight: 800;
    .added-fav {
        color: red;
        text-shadow: $white 0 0 10px;
        transition: all 0.7s ease;
    }
    p {
        color: $gray-1;
        margin-bottom: 5px;
    }
    .row > span {
        font-family: $secondary-font;
        font-weight: 700;
        text-align: left;
        font-size: 2em;
        color: $tertiary;
        width: fit-content;
    }
    h1 {
        font-size: 1.3em !important;
        font-weight: bold;
    }
    h2 {
        font-size: 1.2em !important;
        margin-top: 10px;
    }
    .car-specifications {
        margin: auto;
        max-width: 100%;
        color: $primary-4;
        justify-content: space-around;
        .specification-box {
            position: relative;
            float: left;
            width: fit-content;
            img {
                height: 28px;
                width: auto;
                margin: 0px auto;
                display: block;
            }
            p {
                font-size: 0.6em;
                width: 100%;
                text-align: center;
                margin-top: 10px;
                font-weight: 800;
                color: $gray-1;
            }
        }
    }
    .network-container {
        position: relative;
        float: right;
        right: 0px;
        width: fit-content;
        margin-top: 5px;
        padding: 0px;
        a {
            position: relative;
            float: left;
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
            flex: none;
            color: #ffffff;
            background-color: #333;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            cursor: pointer;
            margin: 0 10px 10px 0;
            text-decoration: none;
            padding: 5px;
            @include box-shadow;
            border-radius: $border-radius;
            &:hover {
                opacity: 0.9;
            }
            img {
                width: 25px;
                height: auto;
            }
        }
    }
}

.contact-form {
    border-radius: $border-radius-deep;
}
.maps {
    height: 270px;
    overflow: hidden;
    iframe {
        border-radius: $border-radius-deep;
    }
}
.skeleton-info-section {
    align-items: left;
    p {
        background-color: $gray-10;
        margin-bottom: 5px;
        color: $gray-10;
        border-radius: $border-radius;
        max-width: 220px;
        border-radius: $border-radius;
        -webkit-animation: ease flickerAnimation 1s infinite;
        -moz-animation: ease flickerAnimation 1s infinite;
        -o-animation: ease flickerAnimation 1s infinite;
        animation: ease flickerAnimation 1s infinite;
    }
    .row > span > div {
        background-color: $gray-10;
        width: 160px;
        height: 45px;
        border-radius: $border-radius;
        -webkit-animation: ease flickerAnimation 1s infinite;
        -moz-animation: ease flickerAnimation 1s infinite;
        -o-animation: ease flickerAnimation 1s infinite;
        animation: ease flickerAnimation 1s infinite;
    }
    h1 {
        background-color: $gray-10;
        color: $gray-10;
        height: 45px;
        border-radius: $border-radius;
        border-radius: $border-radius;
        -webkit-animation: ease flickerAnimation 1s infinite;
        -moz-animation: ease flickerAnimation 1s infinite;
        -o-animation: ease flickerAnimation 1s infinite;
        animation: ease flickerAnimation 1s infinite;
    }
    h2 {
        background-color: $gray-10;
        color: $gray-10;
        height: 35px;
        border-radius: $border-radius;
        border-radius: $border-radius;
        -webkit-animation: ease flickerAnimation 1s infinite;
        -moz-animation: ease flickerAnimation 1s infinite;
        -o-animation: ease flickerAnimation 1s infinite;
        animation: ease flickerAnimation 1s infinite;
    }
    .network-container {
        position: relative;
        float: right;
        right: 0px;
        margin-top: 5px;
        padding: 0px;
        div {
            position: relative;
            float: right;
            width: 45px;
            height: 45px;
            margin: 5px;
            margin-top: -5px;
            background-color: $gray-10;
            border-radius: $border-radius;
            -webkit-animation: ease flickerAnimation 1s infinite;
            -moz-animation: ease flickerAnimation 1s infinite;
            -o-animation: ease flickerAnimation 1s infinite;
            animation: ease flickerAnimation 1s infinite;
        }
    }
    .car-specifications {
        color: $primary-4;
        justify-content: space-around;
        .specification-box {
            position: relative;
            float: left;
            width: fit-content;
            margin-top: 5px;
            div {
                background-color: $gray-10;
                height: 45px;
                width: 45px;
                margin: 0px auto;
                display: block;
                border-radius: $border-radius;
                -webkit-animation: ease flickerAnimation 1s infinite;
                -moz-animation: ease flickerAnimation 1s infinite;
                -o-animation: ease flickerAnimation 1s infinite;
                animation: ease flickerAnimation 1s infinite;
            }
            p {
                background-color: $gray-10;
                width: 55px;
                text-align: center;
                margin-top: 10px;
                color: $gray-10;
                border-radius: $border-radius;
                -webkit-animation: ease flickerAnimation 1s infinite;
                -moz-animation: ease flickerAnimation 1s infinite;
                -o-animation: ease flickerAnimation 1s infinite;
                animation: ease flickerAnimation 1s infinite;
            }
        }
    }
}
</style>
