import { getApiParserBaseUrl } from '@/infrastructure/config';
import HttpClient from './httpClient';

async function doLogin(query) {
    try {
        const loginResponse = await HttpClient.post(`${getApiParserBaseUrl()}/login`, query);
        return loginResponse;
    } catch (err) {
        throw err;
    }
}

function doRedirectToGoogle() {
    window.location.href = `${getApiParserBaseUrl()}/login/google/redirect`;
}

function doRedirectToFacebook() {
    window.location.href = `${getApiParserBaseUrl()}/login/facebook/redirect`;
}

async function doLogout(query) {
    try {
        const logoutResponse = await HttpClient.post(`${getApiParserBaseUrl()}/user/logout`, query);
        return logoutResponse;
    } catch (err) {
        throw err;
    }
}

async function register(query) {
    try {
        const registerResponse = await HttpClient.post(`${getApiParserBaseUrl()}/signup`, query);
        return registerResponse;
    } catch (err) {
        throw err;
    }
}

async function doSendVerificationEmail() {
    try {
        const sendVerificationEmailResponse = await HttpClient.post(`${getApiParserBaseUrl()}/user/send/verification`);
        return sendVerificationEmailResponse;
    } catch (err) {
        throw err;
    }
}

async function doSendResetPasswordEmail(query) {
    try {
        const sendVerificationEmailResponse = await HttpClient.post(`${getApiParserBaseUrl()}/reset/password`, query);
        return sendVerificationEmailResponse;
    } catch (err) {
        throw err;
    }
}

export {
    doLogin,
    doRedirectToGoogle,
    doRedirectToFacebook,
    doLogout,
    register,
    doSendVerificationEmail,
    doSendResetPasswordEmail,
};
