<template>
    <div class="nav-bar" id="nav-bar">
        <router-link :to="{ name: 'home' }" v-if="this.$route.name === 'home'">
            <v-btn prepend-icon="mdi mdi-magnify" class="button-selected" stacked variant="plain">Inicio</v-btn>
        </router-link>
        <router-link :to="{ name: 'home' }" v-else>
            <v-btn prepend-icon="mdi mdi-magnify" stacked variant="plain">Inicio</v-btn>
        </router-link>

        <!-- Favourites -->
        <template v-if="this.$store.getters['user/isAuthenticated']">
            <router-link :to="{ name: 'home' }" v-if="this.$route.name === 'favourites'">
                <v-btn prepend-icon="mdi mdi-heart" class="button-selected" stacked variant="plain">Favoritos</v-btn>
            </router-link>
            <router-link :to="{ name: 'favourites' }" v-else>
                <v-btn prepend-icon="mdi mdi-heart-outline button" stacked variant="plain">Favoritos</v-btn>
            </router-link>
        </template>
        <router-link :to="{ name: 'login' }" v-else>
            <v-btn prepend-icon="mdi mdi-heart-outline button" stacked variant="plain">Favoritos</v-btn>
        </router-link>

        <!-- History -->
        <template v-if="this.$store.getters['user/isAuthenticated']">
            <router-link :to="{ name: 'home' }" v-if="this.$route.name === 'history'">
                <v-btn prepend-icon="mdi mdi-history" class="button-selected" stacked variant="plain">Recientes</v-btn>
            </router-link>
            <router-link :to="{ name: 'history' }" v-else>
                <v-btn prepend-icon="mdi mdi-history button" stacked variant="plain">Recientes</v-btn>
            </router-link>
        </template>
        <router-link :to="{ name: 'login' }" v-else>
            <v-btn prepend-icon="mdi mdi-history button" stacked variant="plain">Recientes</v-btn>
        </router-link>

        <!-- Profile and login -->
        <template v-if="this.$store.getters['user/isAuthenticated']">
            <router-link :to="{ name: 'home' }" v-if="this.$route.name === 'profile'">
                <v-btn prepend-icon="mdi mdi-face-man-profile" class="button-selected" stacked variant="plain">
                    Mi perfil
                </v-btn>
            </router-link>
            <router-link :to="{ name: 'profile' }" v-else>
                <v-btn prepend-icon="mdi mdi-face-man-profile button" stacked variant="plain">Mi perfil</v-btn>
            </router-link>
        </template>
        <router-link :to="{ name: 'login' }" v-else>
            <v-btn prepend-icon="mdi mdi-account-circle button" stacked variant="plain">Conéctate</v-btn>
        </router-link>
    </div>

    <!-- Action modals -->
    <v-dialog
        id="login-register-modal"
        class="align-center justify-center filters-overlay login-modal"
        scrollable
        transition="dialog-bottom-transition"
        :model-value="showLoginModal"
    >
        <v-toolbar density="compact" color="white">
            <v-toolbar-title class="filter-top-title ml-5">¡Inicia sesión y encuentra tu futuro coche!</v-toolbar-title>
            <v-btn icon @click="this.$store.dispatch('user/setShowLoginModal', false)">
                <v-icon size="small">mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <img src="@/assets/images/rocket.svg" class="pop-up-image" alt="Inicia sesión para potenciar la búsqueda" />

        <div class="container col-12 col-md-8 col-xl-6 login-form">
            <GoogleAuth
                :processingAuth="this.googleAuthProcessing"
                @click="this.googleAuthProcessing = !this.googleAuthProcessing"
            />
            <FacebookAuth
                :processingAuth="this.facebookAuthProcessing"
                @click="this.facebookAuthProcessing = !this.facebookAuthProcessing"
            />

            <p class="mt-5 mb-5">
                Al continuar, aceptas el
                <a target="_blank" href="https://about.alertacoches.es/aviso-legal" @click.stop>Aviso legal</a>
                , y confirmas que has leído la
                <a target="_blank" href="https://about.alertacoches.es/politica-privacidad" @click.stop>
                    Política de privacidad
                </a>
                y la
                <a target="_blank" href="https://about.alertacoches.es/politica-cookies" @click.stop>
                    Política de cookies
                </a>
                para saber cómo recopilamos, usamos y compartimos tus datos, así como cómo usamos las cookies.
            </p>
        </div>
        <div class="container col-11 col-md-8 col-xl-6 secondary-login">
            <hr />
            <p>o</p>
            <EmailAuth @click="this.$store.dispatch('user/setShowLoginModal', false)" />
        </div>
    </v-dialog>

    <v-dialog
        id="verify-account-modal"
        class="align-center justify-center filters-overlay login-modal"
        scrollable
        transition="dialog-bottom-transition"
        :model-value="showVerifyModal"
    >
        <v-toolbar density="compact" color="white">
            <v-toolbar-title class="filter-top-title ml-5">Verifica tu cuenta</v-toolbar-title>
            <v-btn icon @click="this.$store.dispatch('user/setShowVerifyModal', false)">
                <v-icon size="small">mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <img src="@/assets/images/rocket.svg" class="pop-up-image" alt="Inicia sesión para potenciar la búsqueda" />
        <p>
            Algunas de las funcionalidades están disponibles sólo para usuarios de verdad. Verifica tu cuenta para poder
            usar esta funcionalidad.
        </p>
        <p>Si no encuentras el email de verificación, accede a tu perfil y solicita que te enviemos otro.</p>

        <p><b>Es posible que tengas que cerrar e iniciar sesión para que se apliquen los cambios.</b></p>
        <div class="container-fluid">
            <div class="container col-12 col-md-6 pb-5 login-components">
                <router-link
                    :to="{ name: 'profile' }"
                    @click.native="this.$store.dispatch('user/setShowVerifyModal', false)"
                >
                    <v-btn block class="mt-3 btn btn-primary">Ir a mi perfil</v-btn>
                </router-link>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import GoogleAuth from '@/components/structural/Login/GoogleAuth.vue';
import FacebookAuth from '@/components/structural/Login/FacebookAuth.vue';
import EmailAuth from '@/components/structural/Login/EmailAuth.vue';
import { mapState } from 'vuex';

export default {
    components: {
        GoogleAuth,
        FacebookAuth,
        EmailAuth,
    },
    data() {
        return {
            googleAuthProcessing: false,
            facebookAuthProcessing: false,
        };
    },
    computed: {
        ...mapState({
            showLoginModal: (state) => state.user.showLoginModal,
            showVerifyModal: (state) => state.user.showVerifyModal,
        }),
    },
    methods: {
        toggleLoginModal() {
            this.showLoginModal = !this.showLoginModal;
        },
        toggleVerifyModal() {
            this.showVerifyModal = !this.showVerifyModal;
        },
    },
    beforeMount() {
        if (this.$route.query.provider === 'google') {
            this.googleAuthProcessing = true;
        } else if (this.$route.query.provider === 'facebook') {
            this.facebookAuthProcessing = true;
        }
        this.$store.dispatch('user/setShowLoginModal', false);
        this.$store.dispatch('user/setShowVerifyModal', false);
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';
.nav-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 10px;
    z-index: 300;
    height: 70px;
    display: flex;
    justify-content: center;
    float: inline-end;
    background-color: $white;
    @include nav-bar-shadow;
    .button-selected {
        border-top: 4px solid $secondary-6 !important;
        span {
            font-size: 0.9em;
            color: $secondary-6 !important;
        }
    }
    button {
        width: 87px;
        height: 55px !important;
        padding: 0px;
        margin: 0px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 4px solid $white;
        padding: 0px 0px;
        span {
            font-size: 0.9em;
            color: $gray;
        }
        .v-btn__content {
            font-family: $main-font;
            font-size: 0.8em;
            text-transform: capitalize;
            font-weight: bold;
            letter-spacing: 0.6px;
        }
    }
}
.login-modal {
    p {
        text-align: center;
        font-size: 12px;
        color: $gray-2;
        a {
            color: $secondary;
        }
    }
    .pop-up-image {
        width: 40%;
        height: auto;
        max-width: 400px;
        max-height: 400px;
        margin: 0 auto;
        display: block;
        margin-top: 20px;
    }
    .v-field {
        height: 66px !important;
        font-size: 13px;
        border-radius: $border-radius-deep !important;
    }
    button {
        letter-spacing: 0.5px;
        font-size: 13px;
        height: 52px !important;
        border-radius: $border-radius;
        span {
            margin: 1px;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .btn-primary {
        background-color: $secondary;
        color: $white;
    }
    .secondary-login {
        bottom: 0;
        padding: 0px;
        padding-bottom: 80px;
    }
}
//xs
@media (max-width: $breakpoint-xs) {
}
//sm
@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
}
//md
@media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    .heading-section {
        height: 230px;
        .overimage {
            max-width: 100%;
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
        }
        .heading-picture {
            max-width: 100%;
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
            overflow: hidden;
            .img-heading {
                border-bottom-left-radius: $border-radius-deep !important;
                border-bottom-right-radius: $border-radius-deep !important;
            }
        }
    }
}
//lg
@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    .login-modal {
        .pop-up-image {
            width: 30%;
        }
    }
    .heading-section {
        height: 250px;
        .overimage {
            max-width: 100%;
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
        }
        .heading-picture {
            max-width: 100%;
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
            overflow: hidden;
            .img-heading {
                border-bottom-left-radius: $border-radius-deep !important;
                border-bottom-right-radius: $border-radius-deep !important;
            }
        }
    }
}
@media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
}
@media (min-width: $breakpoint-xl) {
    .heading-section {
        height: 280px;
        .overimage {
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
            .heading-logo {
                height: 30px;
            }
            #heading-caption h1 {
                font-size: 1.4em;
                margin: 6% auto;
                text-align: center;
            }
        }
        .heading-picture {
            border-bottom-left-radius: $border-radius-deep !important;
            border-bottom-right-radius: $border-radius-deep !important;
            overflow: hidden;
            .img-heading {
                border-bottom-left-radius: $border-radius-deep !important;
                border-bottom-right-radius: $border-radius-deep !important;
            }
        }
    }
}
</style>
