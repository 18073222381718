import DeviceDetector from 'device-detector-js';
const deviceDetector = new DeviceDetector();

function isMobile() {
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    return device.device.type === 'smartphone';
}

function isTablet() {
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    return device.device.type === 'tablet';
}

function isDesktop() {
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    return device.device.type === 'desktop';
}

function isAndroid() {
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    return device.os.name === 'Android';
}

function isIos() {
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);

    return device.os.name === 'iOS';
}

export { isMobile, isTablet, isDesktop, isAndroid, isIos };
