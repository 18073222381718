import { doUnsubscribeFromSetting } from '@/infrastructure/services/userService';

import store from '@/infrastructure/persistence/store/vuex';

async function unsubscribe(notificationSetting, token) {
    const vuexstore = store.repository;
    try {
        const response = await doUnsubscribeFromSetting(notificationSetting, token);

        if (response.status === 200) {
            vuexstore.dispatch('user/logIn', response.data.user);
            return true;
        }

        if (response.status >= 500) {
            vuexstore.dispatch(
                'feedbackInterface/setErrorResponse',
                'Error inesperado al parar las notificaciones. Vuelve a intentarlo más tarde o inténtalo desde tu perfil.',
            );
            return false;
        }

        vuexstore.dispatch('feedbackInterface/setErrorResponse', response.data.message);
        return false;
    } catch (error) {
        return false;
    }
}

export { unsubscribe };
