<template>
    <div class="page" ref="register">
        <SimpleNavBar></SimpleNavBar>
        <div class="container-fluid">
            <div class="container col-12 col-md-8 col-xl-6 register-form register-components">
                <h1 class="register-header">¡Registrate para potenciar tu búsqueda!</h1>
                <v-form @submit.prevent id="registration-form">
                    <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        label="Nombre completo"
                        theme="light"
                        variant="solo"
                        class="mb-2"
                    ></v-text-field>
                    <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="Correo electrónico"
                        theme="light"
                        variant="solo"
                        class="mb-2"
                    ></v-text-field>
                    <v-text-field
                        type="password"
                        v-model="password"
                        :rules="passwordRules"
                        label="Contraseña"
                        theme="light"
                        variant="solo"
                        class="mb-2"
                    ></v-text-field>
                    <v-text-field
                        type="password"
                        v-model="passwordConfirmation"
                        :rules="passwordRules"
                        label="Repite la contraseña"
                        theme="light"
                        variant="solo"
                        class="mb-2"
                    ></v-text-field>
                    <v-checkbox v-model="terms" :rules="termsRules" class="text-left mb-2">
                        <template v-slot:label>
                            <div>
                                He leído y acepto el
                                <a target="_blank" href="https://about.alertacoches.es/aviso-legal" @click.stop>
                                    Aviso legal
                                </a>
                                , la
                                <a target="_blank" href="https://about.alertacoches.es/politica-privacidad" @click.stop>
                                    Política de privacidad
                                </a>
                                y la
                                <a target="_blank" href="https://about.alertacoches.es/politica-cookies" @click.stop>
                                    Política de cookies
                                </a>
                            </div>
                        </template>
                    </v-checkbox>
                    <v-checkbox v-model="communications" class="text-left mb-2">
                        <template v-slot:label>
                            <div>
                                Sí, deseo recibir comunicaciones comerciales de AlertaCoches y empresas colaboradoras
                            </div>
                        </template>
                    </v-checkbox>
                    <v-btn
                        type="submit"
                        block
                        class="mt-2 mb-4 btn btn-primary"
                        :loading="registrationInProgress"
                        @click="register"
                    >
                        Crear cuenta
                    </v-btn>
                </v-form>
                <router-link :to="{ name: 'login' }">
                    <span class="btn btn-tertiary">Ya tengo cuenta</span>
                </router-link>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/structural/Footer.vue';
import SimpleNavBar from '@/components/structural/SimpleNavBar.vue';
import { signUp } from '@/application/auth/signUp';
import { validate } from '@/infrastructure/services/validator';

export default {
    components: {
        SimpleNavBar,
        Footer,
    },
    data() {
        return {
            name: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            terms: false,
            communications: true,
            nameRules: validate.name,
            emailRules: validate.email,
            passwordRules: validate.password,
            termsRules: validate.terms,
            registrationInProgress: false,
        };
    },
    computed: {},
    async mounted() {
        gtag('event', 'start_registration', {});
    },
    methods: {
        async register() {
            const { name, email, password, passwordConfirmation, terms, communications } = this;

            if (!name || !email || !password || !passwordConfirmation || !terms) {
                return;
            }

            this.registrationInProgress = true;
            let response = await signUp({
                name,
                email,
                password,
                password_confirmation: passwordConfirmation,
                terms,
                communications,
            });

            if (response) {
                gtag('event', 'registrated', {});
                router.push('/login');
            }
            this.registrationInProgress = false;
        },
    },
};
</script>
<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/_mixins.scss';

.register-header {
    font-family: $main-font;
    font-weight: bold;
    font-size: 1.2rem;
    color: $gray-2;
    margin-top: 8%;
    margin-bottom: 25px;
    letter-spacing: 0.7px;
}
.register-form {
    height: 840px;
    border-radius: $border-radius-deep;
    a {
        text-decoration: underline;
    }
}
.register-components {
    .v-field {
        height: 66px !important;
        font-size: 13px;
        border-radius: $border-radius-deep !important;
    }
    button {
        letter-spacing: 0.5px;
        font-size: 13px;
        height: 66px !important;
        border-radius: $border-radius;
        span {
            margin: 1px;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .btn-primary {
        background-color: $secondary;
        color: $white;
    }
}
</style>
